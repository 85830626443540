import React, { useState, useRef, useEffect } from "react";
import styles from "./PersonalInfo.module.css";
// import logo from "../../assets/images/logo.svg";
import { useNavigate } from "react-router-dom";
// import config from "../../config.js";
import Sidebar from "../common/Sidebar.js";
import Button from "../common/Button";
import { protectedApi } from "../../services/api.js";
import Stepper from "../common/ProgressBar.js";
import Input from "../common/Input.js";
import Select from "../common/SelectDropdown.js";
import Header2 from "../common/Header2.js";
const COUNTRY_CODES = [
  { code: "+1", country: "USA/Canada", iso: "US" },
  { code: "+44", country: "UK", iso: "GB" },
  { code: "+61", country: "Australia", iso: "AU" },
  { code: "+64", country: "New Zealand", iso: "NZ" },
  { code: "+86", country: "China", iso: "CN" },
  { code: "+81", country: "Japan", iso: "JP" },
  { code: "+82", country: "South Korea", iso: "KR" },
  { code: "+65", country: "Singapore", iso: "SG" },
  { code: "+60", country: "Malaysia", iso: "MY" },
  { code: "+91", country: "India", iso: "IN" },
  // Add more country codes as needed
];

const CustomSelect = ({ value, onChange, options }) => {
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, []);

  const handleSelect = (code) => {
    onChange({ target: { name: "countryCode", value: code } });
    setIsOpen(false);
  };

  const selectedCountry = options.find((c) => c.code === value);

  return (
    <div className={styles.customSelect} ref={dropdownRef}>
      <div className={styles.selectTrigger} onClick={() => setIsOpen(!isOpen)}>
        <img
          src={`https://flagsapi.com/${selectedCountry?.iso}/flat/48.png`}
          alt="Selected country flag"
          className={styles.flagIcon}
        />
        <span>{value}</span>
        <span className={styles.arrow}>▼</span>
      </div>
      {isOpen && (
        <div className={styles.optionsList}>
          {options.map((country) => (
            <div
              key={country.code}
              className={styles.option}
              onClick={() => handleSelect(country.code)}
            >
              <img
                src={`https://flagsapi.com/${country.iso}/flat/24.png`}
                alt={country.country}
                className={styles.flagIcon}
              />
              <span>{country.code}</span>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

function PersonalInfo() {
  const navigate = useNavigate();

  const [formData, setFormData] = useState({
    firstName: "",
    middleName: "",
    lastName: "",
    email: "",
    countryCode: "+1",
    phoneNumber: "",
    gender: "",
    pronoun: "",
    resume: null,
  });

  const [errors, setErrors] = useState({});
  const [resumeFileName, setResumeFileName] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isInitializing, setIsInitializing] = useState(true);

  useEffect(() => {
    const fetchExistingApplication = async () => {
      try {
        const response = await protectedApi.get("/api/application/");

        if (!response?.data?.results) {
          console.error("Invalid response format:", response);
          return;
        }

        const applications = response.data.results;
        if (applications.length === 0) {
          return;
        }

        const currentApplication = applications[applications.length - 1];

        if (!currentApplication) {
          console.error("No valid application data found");
          return;
        }

        if (currentApplication.id) {
          localStorage.setItem(
            "applicationId",
            currentApplication.id.toString()
          );
        }

        setFormData({
          firstName: currentApplication.first_name ?? "",
          middleName: currentApplication.middle_name ?? "",
          lastName: currentApplication.last_name ?? "",
          email: currentApplication.email ?? "",
          countryCode: currentApplication.phone_number
            ? currentApplication.phone_number.substring(0, 3)
            : "+1",
          phoneNumber: currentApplication.phone_number
            ? currentApplication.phone_number.substring(3)
            : "",
          gender: currentApplication.gender ?? "",
          pronoun: currentApplication.pronoun ?? "",
          resume: null,
        });

        if (currentApplication.resume) {
          const filename = currentApplication.resume.split("/").pop();
          if (filename) {
            setResumeFileName(filename);
          }
        }
      } catch (error) {
        console.error("Error fetching application:", error);
      } finally {
        setIsInitializing(false);
      }
    };

    fetchExistingApplication();
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleFormSubmit = async (e, action = "save") => {
    e.preventDefault();
    setIsLoading(true);

    try {
      const isValid = validateForm();
      if (!isValid) {
        setIsLoading(false);
        alert("Please fill in all required fields correctly");
        return;
      }

      const formDataToSend = new FormData();
      const phoneNumber = formData.phoneNumber?.replace(/\D/g, "");
      const fullPhoneNumber =
        formData.countryCode && phoneNumber
          ? `${formData.countryCode}${phoneNumber}`
          : "";

      const requiredFields = {
        first_name: formData.firstName,
        last_name: formData.lastName,
        email: formData.email,
        phone_number: fullPhoneNumber,
        gender: formData.gender,
        pronoun: formData.pronoun,
      };

      for (const [key, value] of Object.entries(requiredFields)) {
        if (!value) {
          throw new Error(`Missing required field: ${key}`);
        }
        formDataToSend.append(key, value);
      }

      if (formData.middleName)
        formDataToSend.append("middle_name", formData.middleName);
      if (formData.resume) formDataToSend.append("resume", formData.resume);

      const existingApplicationId = localStorage.getItem("applicationId");
      let response;

      if (existingApplicationId) {
        response = await protectedApi.put(
          `/api/application/${existingApplicationId}/`,
          formDataToSend,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
            withCredentials: true,
          }
        );
      } else {
        response = await protectedApi.post(
          "/api/application/",
          formDataToSend,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
            withCredentials: true,
          }
        );
      }

      if (response.status === 200 || response.status === 201) {
        if (Array.isArray(response.data) && response.data.length > 0) {
          localStorage.setItem("applicationId", response.data[0].id);
        } else if (response.data && response.data.id) {
          localStorage.setItem("applicationId", response.data.id);
        }

        if (action === "continue") {
          navigate("/education-info");
        } else {
          alert("Profile saved successfully!");
        }
        setErrors({});
      }
    } catch (error) {
      console.error("Form submission failed:", {
        message: error.message,
        response: error.response?.data,
        status: error.response?.status,
      });
      alert("An error occurred while saving your profile");
    } finally {
      setIsLoading(false);
    }
  };

  const handleSave = (e) => handleFormSubmit(e, "save");
  const handleContinue = (e) => handleFormSubmit(e, "continue");

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      if (file.type === "application/pdf") {
        setFormData((prev) => ({
          ...prev,
          resume: file,
        }));
        setResumeFileName(file.name);
      } else {
        setErrors((prev) => ({
          ...prev,
          resume: "Please upload a PDF file",
        }));
        e.target.value = null; // Reset file input
      }
    }
  };

  const validateForm = () => {
    const newErrors = {};

    if (!formData.firstName) newErrors.firstName = "First name is required";
    if (!formData.lastName) newErrors.lastName = "Last name is required";
    if (!formData.email) {
      newErrors.email = "Email is required";
    } else if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(formData.email)) {
      newErrors.email = "Invalid email format";
    }
    if (!formData.phoneNumber) {
      newErrors.phoneNumber = "Phone Number is required";
    } else if (!/^\d+$/.test(formData.phoneNumber)) {
      newErrors.phoneNumber = "Phone number must contain only numbers";
    }
    if (!formData.gender) newErrors.gender = "Gender is required";
    if (!formData.pronoun) newErrors.pronoun = "Pronoun is required";
    if (!formData.resume && !resumeFileName)
      newErrors.resume = "Resume is required";

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleCancel = () => {
    navigate("/"); // Redirect to personal info on cancel
  };

  const phoneNumberSection = (
    <div className={styles.phoneNumberAmount}>
      <div className={styles.bottomParent}>
        <div className={styles.phoneInputGroup}>
          <CustomSelect
            value={formData.countryCode}
            onChange={handleChange}
            options={COUNTRY_CODES}
          />
          <input
            type="tel"
            name="phoneNumber"
            placeholder="Phone Number"
            value={formData.phoneNumber}
            onChange={handleChange}
            className={styles.phoneInput}
          />
        </div>
        {errors.phoneNumber && (
          <div className={styles.errorText}>{errors.phoneNumber}</div>
        )}
      </div>
    </div>
  );

  const resumeUploadSection = (
    <div className={styles.fileUploadContainer}>
      <input
        type="file"
        accept=".pdf"
        onChange={handleFileChange}
        className={styles.fileInput}
        id="resumeUpload"
        style={{ display: "none" }}
      />
      <label htmlFor="resumeUpload" className={styles.fileInputLabel}>
        {resumeFileName || "Upload Resume (PDF)"}
      </label>
      {formData.resume && (
        <div className={styles.fileName}>
          Selected file: {resumeFileName}
          <button
            type="button"
            className={styles.removeFile}
            onClick={() => {
              setFormData((prev) => ({ ...prev, resume: null }));
              setResumeFileName("");
            }}
          >
            ✕
          </button>
        </div>
      )}
      {errors.resume && <div className={styles.errorText}>{errors.resume}</div>}
    </div>
  );

  return (
    <div className={styles.container}>
      <Header2 />
      <div className={styles.pageLayout}>
        <Sidebar />
        <div className={styles.createProfile}>
          <div className={styles.wrapper}>
            <div className={styles.createYourProfile}>Create Your Profile</div>
            <div className={styles.formcontainer}>
              <div className={styles.formSection}>
                <div className={styles.sectionHeader}>
                  <h2>Personal Information</h2>
                  <Stepper currentStep={1} totalSteps={7} progressWidth={14} />
                </div>

                <form onSubmit={handleContinue}>
                  {errors.general && (
                    <div className={styles.errorText}>{errors.general}</div>
                  )}
                  <div className={styles.inputcontainer}>
                    <div className={styles.row}>
                      <div className={styles.leftcol}>
                        <div>
                          <input
                            type="text"
                            name="firstName"
                            placeholder="Your First Name"
                            value={formData.firstName}
                            onChange={handleChange}
                            className={styles.inputContent}
                          />
                          {errors.firstName && (
                            <div className={styles.errorText}>
                              {errors.firstName}
                            </div>
                          )}
                        </div>

                        <div>
                          <input
                            type="text"
                            name="lastName"
                            placeholder="Your Last Name"
                            value={formData.lastName}
                            onChange={handleChange}
                            className={styles.inputContent}
                          />
                          {errors.lastName && (
                            <div className={styles.errorText}>
                              {errors.lastName}
                            </div>
                          )}
                        </div>

                        <div>
                          <input
                            type="text"
                            name="middleName"
                            placeholder="Your Middle Name (Optional)"
                            value={formData.middleName}
                            onChange={handleChange}
                            className={styles.inputContent}
                          />
                        </div>

                        <div>
                          <input
                            type="email"
                            name="email"
                            placeholder="Your Email Address"
                            value={formData.email}
                            onChange={handleChange}
                            className={styles.inputContent1}
                          />
                          {errors.email && (
                            <div className={styles.errorText}>
                              {errors.email}
                            </div>
                          )}
                        </div>

                        <div className={styles.leftcol}>
                          {phoneNumberSection}

                          <div className={styles.dropdownContainer}>
                            <select
                              name="gender"
                              value={formData.gender}
                              onChange={handleChange}
                              className={styles.genderDropdown}
                            >
                              <option value="" disabled>
                                Gender
                              </option>
                              <option value="Male">Male</option>
                              <option value="Female">Female</option>
                              <option value="Other">Other</option>
                            </select>
                            {errors.gender && (
                              <div className={styles.errorText}>
                                {errors.gender}
                              </div>
                            )}
                          </div>

                          <div className={styles.dropdownContainer}>
                            <select
                              name="pronoun"
                              value={formData.pronoun}
                              onChange={handleChange}
                              className={styles.genderDropdown}
                            >
                              <option value="" disabled>
                                Preferred Pronoun
                              </option>
                              <option value="Mr.">Mr.</option>
                              <option value="Mrs.">Mrs.</option>
                              <option value="Ms.">Ms.</option>
                              <option value="Dr.">Dr.</option>
                            </select>
                            {errors.pronoun && (
                              <div className={styles.errorText}>
                                {errors.pronoun}
                              </div>
                            )}
                          </div>

                          {resumeUploadSection}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className={styles.buttoncontainer}>
                    <Button variant="secondary" onClick={handleCancel}>
                      Cancel
                    </Button>
                    <Button variant="secondary" onClick={handleSave}>
                      Save
                    </Button>
                    <Button
                      variant="primary"
                      type="submit"
                      disabled={isLoading}
                    >
                      {isLoading ? "Processing..." : "Continue"}
                    </Button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default PersonalInfo;
