import React from "react";
import styles from "./Footer.module.css";
import logo from "../../assets/images/logo-white.svg";

const Footer = () => {
  return (
    <footer className={styles.footer}>
      <div className={styles.footer__content}>
        <div className={styles.footer__main}>
          <div className={styles.footer__brand}>
            <img src={logo} alt="TurboBe" className={styles.footer__logo} />
            <p className={styles.footer__contact}>admin@turboBe.com</p>
          </div>

          <div className={styles.footer__links}>
            <div className={styles.footer__column}>
              <h4 className={styles.footer__heading}>Company</h4>
              <a href="/about">About</a>
              <a href="/privacy">Privacy Policy</a>
              <a href="/terms">Terms and Condition</a>
              <a href="/cookies">Cookie Policy</a>
              <a href="/contact">Contact</a>
            </div>

            <div className={styles.footer__column}>
              <h4 className={styles.footer__heading}>Social</h4>
              <a
                href="https://facebook.com"
                target="_blank"
                rel="noopener noreferrer"
              >
                Facebook
              </a>
              <a
                href="https://twitter.com"
                target="_blank"
                rel="noopener noreferrer"
              >
                Twitter
              </a>
              <a
                href="https://linkedin.com"
                target="_blank"
                rel="noopener noreferrer"
              >
                Linkedin
              </a>
              <a
                href="https://instagram.com"
                target="_blank"
                rel="noopener noreferrer"
              >
                Instagram
              </a>
            </div>
          </div>
        </div>

        <div className={styles.footer__divider} />

        <div className={styles.footer__bottom}>
          <p className={styles.footer__copyright}>
            © {new Date().getFullYear()} TurboBe. All rights reserved.
          </p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
