import React from "react";
import styles from "./PricingCard.module.css";
import { useNavigate } from "react-router-dom";

const PricingCard = ({
  title,
  features,
  price,
  originalPrice,
  period,
  featured = false,
}) => {
  const navigate = useNavigate();

  const handleGetStarted = () => {
    navigate("/login");
  };
  return (
    <div
      className={`${styles.pricingCard} ${
        featured ? styles.pricingCardFeatured : ""
      }`}
    >
      {featured && <div className={styles.popularLabel}>Most Popular</div>}
      <div className={styles.cardHeader}>
        <h3 className={styles.title}>{title}</h3>
      </div>

      <ul className={styles.features}>
        {features.map((feature, index) => (
          <li key={index} className={styles.featureItem}>
            <span className={styles.bulletPoint}>•</span>
            <span className={styles.featureText}>{feature}</span>
          </li>
        ))}
      </ul>

      <div className={styles.bottomSection}>
        <div className={styles.priceSection}>
          {originalPrice && (
            <span className={styles.originalPrice}>${originalPrice}</span>
          )}
          <div className={styles.currentPrice}>
            <span className={styles.dollarSign}>$</span>
            <span className={styles.amount}>{price}</span>
            <span className={styles.period}>{period}</span>
          </div>
        </div>
        <button className={styles.getStarted} onClick={handleGetStarted}>
          Get Started
        </button>
      </div>
    </div>
  );
};

export default PricingCard;
