// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Input_inputWrapper__6d80G {
  flex: 1 1;
  position: relative;
}

.Input_inputField__pkK7G {
  width: 100%;
  padding: 12px;
  font-size: 16px;
  color: #6c757d;
  background-color: rgba(239, 241, 249, 0.6);
  border: 1px solid #ced4da;
  border-radius: 8px;
  box-sizing: border-box;
  transition: all 0.2s ease-in-out;
}

.Input_inputField__pkK7G:hover {
  border-color: #999999;
}

.Input_inputField__pkK7G:focus {
  outline: none;
  border-color: #1e70bb;
  background-color: #ffffff;
}

.Input_errorInput__dxLU9 {
  border-color: #ff4d4f;
}

.Input_errorMessage__Q7Uin {
  color: #ff4d4f;
  font-size: 12px;
  position: absolute;
  bottom: -20px;
  left: 0;
}

.Input_phoneInputGroup__UPfv5 {
  display: flex;
  gap: 10px;
  align-items: center;
}

.Input_phoneInput__3ptCi {
  flex: 1 1;
  height: 40px;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
} `, "",{"version":3,"sources":["webpack://./src/components/common/Input.module.css"],"names":[],"mappings":"AAAA;EACE,SAAO;EACP,kBAAkB;AACpB;;AAEA;EACE,WAAW;EACX,aAAa;EACb,eAAe;EACf,cAAc;EACd,0CAA0C;EAC1C,yBAAyB;EACzB,kBAAkB;EAClB,sBAAsB;EACtB,gCAAgC;AAClC;;AAEA;EACE,qBAAqB;AACvB;;AAEA;EACE,aAAa;EACb,qBAAqB;EACrB,yBAAyB;AAC3B;;AAEA;EACE,qBAAqB;AACvB;;AAEA;EACE,cAAc;EACd,eAAe;EACf,kBAAkB;EAClB,aAAa;EACb,OAAO;AACT;;AAEA;EACE,aAAa;EACb,SAAS;EACT,mBAAmB;AACrB;;AAEA;EACE,SAAO;EACP,YAAY;EACZ,YAAY;EACZ,sBAAsB;EACtB,kBAAkB;AACpB","sourcesContent":[".inputWrapper {\n  flex: 1;\n  position: relative;\n}\n\n.inputField {\n  width: 100%;\n  padding: 12px;\n  font-size: 16px;\n  color: #6c757d;\n  background-color: rgba(239, 241, 249, 0.6);\n  border: 1px solid #ced4da;\n  border-radius: 8px;\n  box-sizing: border-box;\n  transition: all 0.2s ease-in-out;\n}\n\n.inputField:hover {\n  border-color: #999999;\n}\n\n.inputField:focus {\n  outline: none;\n  border-color: #1e70bb;\n  background-color: #ffffff;\n}\n\n.errorInput {\n  border-color: #ff4d4f;\n}\n\n.errorMessage {\n  color: #ff4d4f;\n  font-size: 12px;\n  position: absolute;\n  bottom: -20px;\n  left: 0;\n}\n\n.phoneInputGroup {\n  display: flex;\n  gap: 10px;\n  align-items: center;\n}\n\n.phoneInput {\n  flex: 1;\n  height: 40px;\n  padding: 8px;\n  border: 1px solid #ccc;\n  border-radius: 4px;\n} "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"inputWrapper": `Input_inputWrapper__6d80G`,
	"inputField": `Input_inputField__pkK7G`,
	"errorInput": `Input_errorInput__dxLU9`,
	"errorMessage": `Input_errorMessage__Q7Uin`,
	"phoneInputGroup": `Input_phoneInputGroup__UPfv5`,
	"phoneInput": `Input_phoneInput__3ptCi`
};
export default ___CSS_LOADER_EXPORT___;
