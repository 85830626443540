import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Sidebar from "../common/Sidebar.js";
import Header2 from "../common/Header2.js";
import styles from "./petition_letter.module.css";
import Button from "../common/Button.js";
import { protectedApi } from "../../services/api.js";

// function PetitionLetter() {
//   const navigate = useNavigate();
//   const [activeTab, setActiveTab] = useState("personal");
//   const [isLoading, setIsLoading] = useState(true);
//   const [isGenerating, setIsGenerating] = useState(false);
//   const [error, setError] = useState(null);
//   const [formData, setFormData] = useState({
//     personalInfo: null,
//     education: null,
//     projects: null,
//     scientificProjects: null,
//     recommendationLetters: null,
//   });
//   const [applicationId, setApplicationId] = useState(null);

//   // First, fetch the active application
//   useEffect(() => {
//     const fetchActiveApplication = async () => {
//       try {
//         const response = await protectedApi.get("/api/active-application");
//         if (response.data && response.data.id) {
//           setApplicationId(response.data.id);
//         } else {
//           setError(
//             "No active application found. Please create an application first."
//           );
//         }
//       } catch (error) {
//         setError("Failed to fetch active application. Please try again later.");
//         console.error("Error fetching active application:", error);
//       }
//     };

//     fetchActiveApplication();
//   }, []);

//   // Then fetch form data only if we have an application ID
//   useEffect(() => {
//     if (applicationId) {
//       fetchAllFormData();
//     }
//   }, [applicationId]);

//   const fetchAllFormData = async () => {
//     setIsLoading(true);
//     setError(null);

//     try {
//       const endpoints = [
//         { key: "personalInfo", url: "/api/personal-info" },
//         { key: "education", url: "/api/academichistory" },
//         { key: "projects", url: "/api/academicprojects" },
//         { key: "scientificProjects", url: "/api/professionalhistory" },
//         { key: "recommendationLetters", url: "/api/recommendationletters" },
//       ];

//       const results = await Promise.all(
//         endpoints.map(async ({ url }) => {
//           try {
//             const response = await protectedApi.get(url);
//             return response.data;
//           } catch (error) {
//             console.error(`Error fetching ${url}:`, error);
//             throw error;
//           }
//         })
//       );

//       const newFormData = endpoints.reduce((acc, { key }, index) => {
//         acc[key] = results[index];
//         return acc;
//       }, {});

//       setFormData(newFormData);
//     } catch (error) {
//       setError("Failed to fetch form data. Please try again later.");
//       console.error("Error fetching form data:", error);
//     } finally {
//       setIsLoading(false);
//     }
//   };

//   const handleGenerate = async () => {
//     if (!applicationId) {
//       setError(
//         "No active application found. Please create an application first."
//       );
//       return;
//     }

//     setIsGenerating(true);
//     setError(null);

//     try {
//       const response = await protectedApi.post(
//         `/api/generate-petition/${applicationId}`,
//         formData,
//         {
//           responseType: "blob", // Important for file download
//         }
//       );

//       // Create and trigger download
//       const blob = new Blob([response.data], { type: "application/pdf" });
//       const url = window.URL.createObjectURL(blob);
//       const link = document.createElement("a");
//       link.href = url;
//       link.setAttribute("download", `petition-letter-${applicationId}.pdf`);
//       document.body.appendChild(link);
//       link.click();

//       // Cleanup
//       window.URL.revokeObjectURL(url);
//       link.remove();

//       navigate("/dashboard");
//     } catch (error) {
//       setError("Failed to generate petition letter. Please try again later.");
//       console.error("Generation failed:", error);
//     } finally {
//       setIsGenerating(false);
//     }
//   };

//   // Render functions with error handling
//   const renderSection = (title, content) => (
//     <div className={styles.reviewSection}>
//       <h2>{title}</h2>
//       {content || <p className={styles.noData}>No data available</p>}
//     </div>
//   );

//   const renderPersonalInfo = () =>
//     renderSection(
//       "Personal Information Review",
//       formData.personalInfo && (
//         <div className={styles.reviewContent}>
//           <p>
//             <strong>Name:</strong> {formData.personalInfo.name || "N/A"}
//           </p>
//           <p>
//             <strong>Email:</strong> {formData.personalInfo.email || "N/A"}
//           </p>
//           {/* Add other personal info fields */}
//         </div>
//       )
//     );

//   // Similar render functions for other sections...

//   if (isLoading) {
//     return (
//       <div className={styles.loadingContainer}>
//         <div className={styles.loadingSpinner}>
//           <div className={styles.spinnerIcon}></div>
//           <p>Loading your application data...</p>
//         </div>
//       </div>
//     );
//   }

//   return (
//     <div className={styles.container}>
//       <Header2 />
//       <div className={styles.pageLayout}>
//         <Sidebar />
//         <div className={styles.mainContent}>
//           <div className={styles.wrapper}>
//             <h1 className={styles.title}>Petition Letter Review</h1>

//             {error && <div className={styles.errorMessage}>{error}</div>}

//             {!error && (
//               <>
//                 <div className={styles.formTabs}>
//                   {[
//                     "personal",
//                     "education",
//                     "projects",
//                     "scientific",
//                     "recommendations",
//                   ].map((tab) => (
//                     <button
//                       key={tab}
//                       className={`${styles.tab} ${
//                         activeTab === tab ? styles.activeTab : ""
//                       }`}
//                       onClick={() => setActiveTab(tab)}
//                     >
//                       {tab.charAt(0).toUpperCase() + tab.slice(1)}
//                     </button>
//                   ))}
//                 </div>

//                 <div className={styles.formContainer}>
//                   {activeTab === "personal" && renderPersonalInfo()}
//                   {activeTab === "education" && renderEducation()}
//                   {activeTab === "projects" && renderProjects()}
//                   {activeTab === "scientific" && renderScientificProjects()}
//                   {activeTab === "recommendations" &&
//                     renderRecommendationLetters()}
//                 </div>

//                 <div className={styles.generateSection}>
//                   <Button
//                     onClick={handleGenerate}
//                     disabled={isGenerating || !applicationId}
//                     className={styles.generateButton}
//                   >
//                     {isGenerating
//                       ? "Generating..."
//                       : "Generate Petition Letter"}
//                   </Button>
//                 </div>
//               </>
//             )}
//           </div>
//         </div>
//       </div>

//       {isGenerating && (
//         <div className={styles.loadingOverlay}>
//           <div className={styles.loadingSpinner}>
//             <div className={styles.spinnerIcon}></div>
//             <p>Generating your petition letter...</p>
//             <p>This may take a few minutes</p>
//           </div>
//         </div>
//       )}
//     </div>
//   );
// }

// export default PetitionLetter;

function PetitionLetter() {
  const [generationState, setGenerationState] = useState("initial"); // 'initial', 'generating', 'complete'

  const handleGenerate = () => {
    setGenerationState("generating");
    // Simulate generation process
    setTimeout(() => {
      setGenerationState("complete");
    }, 2000);
  };

  return (
    <div className={styles.container}>
      <Header2 />
      <div className={styles.pageLayout}>
        <Sidebar />
        <div className={styles.mainContent}>
          <div className={styles.wrapper}>
            <h1 className={styles.title}>Petition Letter </h1>
            <div className={styles.aiGeneratorCard}>
              <div className={styles.generatorHeader}>
                <div className={styles.headerLeft}>
                  <span className={styles.aiIcon}>✨</span>
                  <span>Endeavor AI Generator</span>
                </div>
                <button className={styles.collapseButton}>
                  <span>▼</span>
                </button>
              </div>
              <div className={styles.generatorContent}>
                <div className="generatorBox">
                  {generationState === "initial" && (
                    <>
                      <p>
                        Generate your petition letter based on your profile,
                        program criteria, and recommendations.
                      </p>
                      <div
                        style={{
                          width: "100%",
                          display: "flex",
                          justifyContent: "center",
                        }}
                      >
                        <button
                          className={styles.generateButton}
                          onClick={handleGenerate}
                        >
                          Generate Now
                        </button>
                      </div>
                    </>
                  )}

                  {generationState === "generating" && (
                    <>
                      <p>Petition Letter Generation in progress...</p>
                      <div className={styles.progressBar}>
                        <div className={styles.progress}></div>
                      </div>
                      <p>67% Complete...</p>
                    </>
                  )}

                  {generationState === "complete" && (
                    <>
                      <p>
                        Congratulations! Your petition letter has been
                        successfully generated!
                      </p>
                      <div
                        style={{
                          width: "100%",
                          display: "flex",
                          justifyContent: "center",
                        }}
                      >
                        <button className={styles.generateButton}>
                          Download
                        </button>
                      </div>
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default PetitionLetter;
