import styles from "./LandingPage.module.css";
import { useNavigate } from "react-router-dom";
import metaLogo from "../assets/images/meta-logo.png";
import mcKinseyLogo from "../assets/images/mckinsey-logo.png";
import tiktokLogo from "../assets/images/tiktok-logo.png";
import dashboardPreview from "../assets/images/dashboard-preview.png";
import logo from "../assets/images/logo.svg";
import React, { useEffect, useRef } from "react";
import Header from "../components/common/Header";
import Footer from "../components/common/Footer";
import PricingCard from "../components/common/PricingCard";
import "./LandingPage.module.css";

const LandingPage = () => {
  const navigate = useNavigate();
  const statsRef = useRef(null);
  const numbersRef = useRef([]);
  const processRef = useRef(null);
  const aiLetterRef = useRef(null);
  const hasAnimated = useRef(false);
  const heroRightRef = useRef(null);
  const expertiseRef = useRef(null);
  const pricingSectionRef = useRef(null);

  useEffect(() => {
    const animateValue = (element, start, end, duration) => {
      let startTimestamp = null;
      const step = (timestamp) => {
        if (!startTimestamp) startTimestamp = timestamp;
        const progress = Math.min((timestamp - startTimestamp) / duration, 1);
        const currentValue = Math.floor(progress * (end - start) + start);
        element.textContent = currentValue;
        if (progress < 1) {
          window.requestAnimationFrame(step);
        }
      };
      window.requestAnimationFrame(step);
    };

    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting && !hasAnimated.current) {
            // Animate container
            entry.target.classList.add(styles.animate);

            // Animate numbers
            numbersRef.current.forEach((numElement, index) => {
              setTimeout(() => {
                numElement.classList.add(styles.animate);
                const targetValue = index === 0 || index === 1 ? 30 : 0;
                animateValue(numElement, 0, targetValue, 2000);
              }, index * 200); // Stagger the animations
            });

            hasAnimated.current = true;
          }
        });
      },
      {
        threshold: 0.2,
        rootMargin: "0px",
      }
    );

    if (statsRef.current) {
      observer.observe(statsRef.current);
    }

    return () => observer.disconnect();
  }, []);

  const handleGetStarted = () => {
    navigate("/login");
  };

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            entry.target.classList.add(styles.animate);
          }
        });
      },
      {
        threshold: 0.2,
        rootMargin: "-50px",
      }
    );

    if (processRef.current) {
      observer.observe(processRef.current);
    }

    return () => observer.disconnect();
  }, []);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            entry.target.classList.add(styles.animate);
          }
        });
      },
      {
        threshold: 0.2,
        rootMargin: "-50px",
      }
    );

    if (aiLetterRef.current) {
      observer.observe(aiLetterRef.current);
    }

    return () => observer.disconnect();
  }, []);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            entry.target.classList.add(styles.animate);
          }
        });
      },
      {
        threshold: 0.1,
      }
    );

    if (heroRightRef.current) {
      observer.observe(heroRightRef.current);
    }

    return () => observer.disconnect();
  }, []);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            entry.target.classList.add(styles.animate);
          }
        });
      },
      {
        threshold: 0.2,
        rootMargin: "-50px",
      }
    );

    if (expertiseRef.current) {
      observer.observe(expertiseRef.current);
    }

    return () => observer.disconnect();
  }, []);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            entry.target.classList.add(styles.animate);
          }
        });
      },
      {
        threshold: 0.1,
        rootMargin: "50px",
      }
    );

    if (pricingSectionRef.current) {
      observer.observe(pricingSectionRef.current);
    }

    return () => {
      if (pricingSectionRef.current) {
        observer.unobserve(pricingSectionRef.current);
      }
    };
  }, []);

  return (
    <div className={styles.landingPage}>
      <Header />

      <section className={styles.hero}>
        <div className={styles.heroLeft}>
          <h1 className={styles.heroTitle}>
            Your NIW Application Perfected in{" "}
            <span className={styles.highlight}>One Week</span>
          </h1>
          <p className={styles.heroText}>
            From expertly crafted letters to comprehensive evidence backing, our
            services provide everything you need to submit a strong NIW
            application. Packages starting at $1049.
          </p>
          <button className={styles.getStarted} onClick={handleGetStarted}>
            Get Started
          </button>

          <div className={styles.trustedBy}>
            <span className={styles.trustedText}>Trusted by talents in</span>
            <div className={styles.logoGroup}>
              <img src={metaLogo} alt="Meta logo" />
              <div className={styles.divider} />
              <img src={mcKinseyLogo} alt="McKinsey logo" />
              <div className={styles.divider} />
              <img src={tiktokLogo} alt="TikTok logo" />
            </div>
          </div>
        </div>

        <div className={styles.heroRight} ref={heroRightRef}>
          <img
            src={dashboardPreview}
            alt="Dashboard preview"
            className={styles.dashboardImage}
          />
        </div>
      </section>

      {/* Stats Section */}
      <section className={styles.stats}>
        <div className={styles.statsContainer} ref={statsRef}>
          <div className={styles.statsItem}>
            <span
              className={styles.statsNumber}
              ref={(el) => (numbersRef.current[0] = el)}
            >
              0
            </span>
            <p className={styles.statsText}>
              One-Time
              <br />
              Approvals
            </p>
          </div>
          <div className={styles.statsItem}>
            <span
              className={styles.statsNumber}
              ref={(el) => (numbersRef.current[1] = el)}
            >
              0
            </span>
            <p className={styles.statsText}>
              RFE
              <br />
              Approvals
            </p>
          </div>
          <div className={styles.statsItem}>
            <span
              className={styles.statsNumber}
              ref={(el) => (numbersRef.current[2] = el)}
            >
              0
            </span>
            <p className={styles.statsText}>
              Industry
              <br />
              limitations
            </p>
          </div>
          <div className={styles.statsItem}>
            <span
              className={styles.statsNumber}
              ref={(el) => (numbersRef.current[3] = el)}
            >
              0
            </span>
            <p className={styles.statsText}>
              Publication
              <br />
              Needed
            </p>
          </div>
        </div>
      </section>

      {/* Process Section */}
      <section className={styles.process} ref={processRef}>
        <div className={styles.processContent}>
          <div className={`${styles.processLeft} ${styles.fadeSlideLeft}`}>
            <h2 className={styles.processTitle}>Effortless and Secure</h2>
            <h3 className={styles.processSubtitle}>Application Process</h3>
            <p className={styles.processText}>
              Our step-by-step guidance minimizes complexity at every stage,
              while rigorous data protection measures keep your personal
              information safe and confidential throughout your NIW application
              journey.
            </p>
            <button className={styles.createProfile} onClick={handleGetStarted}>
              Create Profile
              <span className={styles.buttonIcon}></span>
            </button>
          </div>

          <div className={`${styles.processRight} ${styles.fadeSlideRight}`}>
            <div className={styles.mockupContainer}>
              <div className={styles.progressBarVertical}>
                <div className={styles.progressLine}></div>
                <div className={styles.progressSteps}>
                  {/* Step 1 */}
                  <div className={`${styles.step} ${styles.active}`}>
                    <div className={styles.stepCircle}>1</div>
                    <div className={styles.stepContent}>
                      <h4>Create Profile and upload documents</h4>
                      <p>Set up your account with basic information</p>
                      <div className={styles.stepPreview}>
                        <div className={styles.formPreview}>
                          <div> Personal Information: </div>
                          <div className={styles.inputField}></div>
                          <div> Education History: </div>
                          <div className={styles.inputField}></div>
                          <div> Work & Projects Experience: </div>
                          <div className={styles.inputField}></div>
                          <div>...</div>

                          <div
                            className={styles.inputField}
                            style={{ width: "70%" }}
                          ></div>
                        </div>
                      </div>
                    </div>
                  </div>

                  {/* Step 2 */}
                  <div
                    className={`${styles.step} ${styles.active}`}
                    style={{ animationDelay: "0.4s" }}
                  >
                    <div className={styles.stepCircle}>2</div>
                    <div className={styles.stepContent}>
                      <h4>Select Proposed Endeavor</h4>
                      <p>
                        Select/Edit the proposed endeavors we provided for you
                      </p>
                      <div className={styles.stepPreview}>
                        <div className={styles.endeavorSelection}>
                          <div className={styles.selectWrapper}>
                            <div className={styles.endeavorContainer}>
                              <div className={styles.endeavorItem}>
                                <div className={styles.endeavorHeader}>
                                  <h5>Data-driven Strategies</h5>

                                  <input
                                    type="checkbox"
                                    className={styles.endeavorCheck}
                                  />
                                </div>
                                <div className={styles.endeavorDetails}>
                                  <p>
                                    Develop data-driven strategies to enhance
                                    small and medium-sized businesses' (SMBs)
                                    online presence and competitiveness.
                                  </p>
                                </div>
                              </div>

                              <div className={styles.endeavorItem}>
                                <div className={styles.endeavorHeader}>
                                  <h5>Data quality and accuracy</h5>

                                  <input
                                    type="checkbox"
                                    className={styles.endeavorCheck}
                                  />
                                </div>
                                <div className={styles.endeavorDetails}>
                                  <p>
                                    Improve data quality and accuracy in
                                    e-commerce platforms by developing and
                                    implementing advanced metrics and validation
                                    techniques to benefit both consumers and
                                    businesses in the U.S.
                                  </p>
                                </div>
                              </div>

                              <div className={styles.endeavorItem}>
                                <div className={styles.endeavorHeader}>
                                  <h5>Fraud Detection</h5>

                                  <input
                                    type="checkbox"
                                    className={styles.endeavorCheck}
                                  />
                                </div>
                                <div className={styles.endeavorDetails}>
                                  <p>...</p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  {/* Step 3 */}
                  <div className={`${styles.step} ${styles.active}`}>
                    <div className={styles.stepCircle}>3</div>
                    <div className={styles.stepContent}>
                      <h4>Review and Download Recommendation Letter</h4>
                      <p>Final check before submission</p>
                      <div className={styles.stepPreview}>
                        <div className={styles.checklistPreview}>
                          <div className={styles.checkItem}>
                            Profile Complete
                          </div>
                          <div className={styles.checkItem}>
                            Referee Information Verified
                          </div>
                          <div className={styles.checkItem}>
                            Proposed Endeavor Selected
                          </div>
                          <div className={styles.checkItem}>
                            Recommendation Letter Generated
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* Step 4 */}
                  <div className={`${styles.step} ${styles.active}`}>
                    <div className={styles.stepCircle}>4</div>
                    <div className={styles.stepContent}>
                      <h4>Review and Download Petition Letter</h4>
                      <p>Final check before submission</p>
                      <div className={styles.stepPreview}>
                        <div className={styles.checklistPreview}>
                          <div className={styles.checkItem}>
                            Petition Letter Generated
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* Expertise Hero Section */}
      <section className={styles.expertiseHero} ref={expertiseRef}>
        <div className={`${styles.expertiseLeft} ${styles.fadeSlideLeft}`}>
          <div className={styles.expertiseContent}>
            <h2 className={styles.expertiseTitle}>
              Personalized Petition Development
            </h2>
            <h3 className={styles.expertiseSubtitle}>
              with Industry Expertise
            </h3>
            <p className={styles.expertiseText}>
              Our team of seasoned NIW professionals customizes each application
              to showcase your unique achievements, ensuring that your petition
              reflects both your professional strengths and the highest industry
              standards.
            </p>
          </div>
        </div>

        <div className={`${styles.expertiseRight} ${styles.fadeSlideRight}`}>
          <div className={styles.expertiseMockup}>
            <div className={styles.mockupMain}>
              <div className={styles.mockupContent}>
                <h3>View Your Proposed Endeavors</h3>
                <div className={styles.mockupGrid}>
                  <div className={styles.mockupItem}>
                    <div className={styles.formLine}></div>
                    <div
                      className={styles.formLine}
                      style={{ width: "80%" }}
                    ></div>
                  </div>
                  <div className={styles.mockupItem}>
                    <div className={styles.formLine}></div>
                    <div
                      className={styles.formLine}
                      style={{ width: "60%" }}
                    ></div>
                  </div>
                </div>
                <div className={styles.mockupFooter}>
                  <button className={styles.mockupButton}>Previous</button>
                  <button
                    className={`${styles.mockupButton} ${styles.primary}`}
                  >
                    Continue
                  </button>
                </div>
              </div>
            </div>
            <div className={styles.mockupSecondary}>
              {/* Secondary mockup content */}
            </div>
          </div>
          <div className={styles.expertiseDecor1}></div>
          <div className={styles.expertiseDecor2}></div>
        </div>
      </section>

      {/* AI Letter Hero Section */}
      <section className={styles.aiLetterHero} ref={aiLetterRef}>
        <div className={`${styles.aiLetterLeft} ${styles.fadeSlideLeft}`}>
          <div className={styles.aiLetterContent}>
            <h2 className={styles.aiLetterTitle}>
              High-Quality AI-Driven Letter Generation
            </h2>
            <h3 className={styles.aiLetterSubtitle}>
              with Robust Evidence Support
            </h3>
            <p className={styles.aiLetterText}>
              Leveraging advanced AI and a comprehensive evidence library, our
              platform quickly generates high-impact letters that are both
              accurate and persuasive, strengthening your NIW application with
              credible, well-supported content.
            </p>
            <button
              className={styles.generateButton}
              onClick={handleGetStarted}
            >
              Generate My Letter
              <span className={styles.buttonIcon}>→</span>
            </button>
          </div>
        </div>

        <div className={`${styles.aiLetterRight} ${styles.fadeSlideRight}`}>
          <div className={styles.letterMockup}>
            <div className={styles.letterMain}>
              <div className={styles.letterHeader}>
                <div className={styles.letterDate}>March 19, 2024</div>

                <div className={styles.letterTo}>To Whom It May Concern:</div>

                <div className={styles.letterRef}>
                  Re: Letter of Recommendation for National Interest Waiver
                </div>
              </div>

              <div className={styles.letterContent}>
                <p>
                  I am writing to enthusiastically recommend Dr. Sarah Chen for
                  a National Interest Waiver (NIW). As the Director of Research
                  at Google Brain, I have closely followed Dr. Chen's
                  exceptional contributions to deep learning and neural network
                  architectures over the past five years.
                </p>

                <p>
                  Dr. Chen's research in efficient deep learning architectures
                  has been particularly impactful for democratizing AI
                  technology. Her innovations in model compression and knowledge
                  distillation have made it possible to deploy sophisticated AI
                  models on mobile devices, directly benefiting millions of
                  users worldwide.
                </p>

                <div className={styles.letterSignature}>
                  <div className={styles.signatureName}>Dr. Jeff Dean</div>
                  <div className={styles.signatureTitle}>
                    Senior Fellow & SVP of Research and AI
                  </div>
                  <div className={styles.signatureInst}>Google Brain</div>
                </div>
              </div>
            </div>
            <div className={styles.typingIndicator}>
              <span></span>
              <span></span>
              <span></span>
            </div>
          </div>
        </div>
      </section>

      {/* Pricing Section */}
      <section ref={pricingSectionRef} className={styles.pricingSection}>
        <div className={styles.pricingHeader}>
          <h2 className={styles.pricingTitle}>
            Select <span className={styles.titleGray}>the</span> Service{" "}
            <span className={styles.titleGray}>Kit That</span> Fits{" "}
            <span className={styles.titleGray}>Your Needs</span>
          </h2>
          <p className={styles.pricingDescription}>
            Explore our range of{" "}
            <span className={styles.textGray}>
              service kits designed to meet the unique requirements of your NIW
            </span>{" "}
            application process.
          </p>
        </div>

        <div className={styles.pricingGrid}>
          <PricingCard
            title="Recommendation Letter Kit"
            features={[
              "Fast, simple evaluation on referee background",
              "AI-powered customized generation based on referee background",
              "Highlight your achievement and emphasize proposed endeavor",
            ]}
            price={149}
            originalPrice={249}
            period="/letter"
          />

          <PricingCard
            title="NIW Full Petition Kit"
            features={[
              "Include Recommendation Kit with 5 letters",
              "Petition Letter generation with numerous exhibition supports and government documents",
              "Instructions, I40 filing",
            ]}
            price={1049}
            originalPrice={1349}
            period="/kit"
            featured={true}
            dividerText="What you'll get"
          />

          <PricingCard
            title="RFE Kit"
            features={[
              "Instructions, I40filings",
              "Recommendation Letter x 5",
              "Petition Letter generation with numerous exhibition supports",
            ]}
            price={1489}
            originalPrice={1349}
            period="/kit"
            dividerText="What you'll get"
          />

          <PricingCard
            title="Elite Kit"
            features={["Placeholder", "Placeholder", "Placeholder"]}
            price={149}
            originalPrice={249}
            period="/kit"
            dividerText="What you'll get"
          />
        </div>
      </section>

      <Footer />
    </div>
  );
};

export default LandingPage;
