import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import Sidebar from "../common/Sidebar.js";
import Header2 from "../common/Header2.js";
import styles from "./recommender_config.module.css";
import Button from "../common/Button.js";
import { protectedApi } from "../../services/api.js";

// import CustomSelect from "../common/SelectDropdown.js";
const COUNTRY_CODES = [
  { code: "+1", country: "USA/Canada", iso: "US" },
  { code: "+44", country: "UK", iso: "GB" },
  { code: "+61", country: "Australia", iso: "AU" },
  { code: "+64", country: "New Zealand", iso: "NZ" },
  { code: "+86", country: "China", iso: "CN" },
  { code: "+81", country: "Japan", iso: "JP" },
  { code: "+82", country: "South Korea", iso: "KR" },
  { code: "+65", country: "Singapore", iso: "SG" },
  { code: "+60", country: "Malaysia", iso: "MY" },
  { code: "+91", country: "India", iso: "IN" },
  // Add more country codes as needed
];

const CustomSelect = ({ value, onChange, options }) => {
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, []);

  const handleSelect = (code) => {
    onChange({ target: { name: "countryCode", value: code } });
    setIsOpen(false);
  };

  const selectedCountry = options.find((c) => c.code === value);
  return (
    <div className={styles.customSelect} ref={dropdownRef}>
      <div className={styles.selectTrigger} onClick={() => setIsOpen(!isOpen)}>
        <img
          src={`https://flagsapi.com/${selectedCountry?.iso}/flat/48.png`}
          alt="Selected country flag"
          className={styles.flagIcon}
        />
        <span>{value}</span>
        <span className={styles.arrow}>▼</span>
      </div>
      {isOpen && (
        <div className={styles.optionsList}>
          {options.map((country) => (
            <div
              key={country.code}
              className={styles.option}
              onClick={() => handleSelect(country.code)}
            >
              <img
                src={`https://flagsapi.com/${country.iso}/flat/24.png`}
                alt={country.country}
                className={styles.flagIcon}
              />
              <span>{country.code}</span>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

const CATEGORIES = [
  { value: "academic", label: "Academic" },
  { value: "work", label: "Work" },
];

function RecommenderConfig() {
  const navigate = useNavigate();
  const [activeTab, setActiveTab] = useState("profile");
  const [formData, setFormData] = useState({
    prefix: "",
    name: "",
    email: "",
    countryCode: "+1",
    phoneNumber: "",
    linkedinUrl: "",
    category: "",
    title: "",
    organization: "",
    department: "",
    relationship: "",
    yearsKnown: "",
    familiarity: "",
  });

  const [errors, setErrors] = useState({});
  const [isLoading, setIsLoading] = useState(false);

  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef(null);

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setIsOpen(false);
    }
  };

  const handleSelect = (code) => {
    handleChange({ target: { name: "countryCode", value: code } });
    setIsOpen(false);
  };
  const handleFormSubmit = async (e, action = "save") => {
    e.preventDefault();
    setIsLoading(true);

    try {
      const isValid = validateForm();
      if (!isValid) {
        setIsLoading(false);
        alert("Please fill in all required fields correctly");
        return;
      }

      const formDataToSend = new FormData();
      const phoneNumber = formData.phoneNumber?.replace(/\D/g, "");
      const fullPhoneNumber =
        formData.countryCode && phoneNumber
          ? `${formData.countryCode}${phoneNumber}`
          : "";

      const requiredFields = {
        first_name: formData.firstName,
        last_name: formData.lastName,
        email: formData.email,
        phone_number: fullPhoneNumber,
        gender: formData.gender,
        pronoun: formData.pronoun,
      };

      for (const [key, value] of Object.entries(requiredFields)) {
        if (!value) {
          throw new Error(`Missing required field: ${key}`);
        }
        formDataToSend.append(key, value);
      }

      if (formData.middleName)
        formDataToSend.append("middle_name", formData.middleName);
      if (formData.resume) formDataToSend.append("resume", formData.resume);

      const existingApplicationId = localStorage.getItem("applicationId");
      let response;

      if (existingApplicationId) {
        response = await protectedApi.put(
          `/api/application/${existingApplicationId}/`,
          formDataToSend,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
            withCredentials: true,
          }
        );
      } else {
        response = await protectedApi.post(
          "/api/application/",
          formDataToSend,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
            withCredentials: true,
          }
        );
      }

      if (response.status === 200 || response.status === 201) {
        if (Array.isArray(response.data) && response.data.length > 0) {
          localStorage.setItem("applicationId", response.data[0].id);
        } else if (response.data && response.data.id) {
          localStorage.setItem("applicationId", response.data.id);
        }

        if (action === "continue") {
          navigate("/education-info");
        } else {
          alert("Profile saved successfully!");
        }
        setErrors({});
      }
    } catch (error) {
      console.error("Form submission failed:", {
        message: error.message,
        response: error.response?.data,
        status: error.response?.status,
      });
      alert("An error occurred while saving your profile");
    } finally {
      setIsLoading(false);
    }
  };

  const handleSave = (e) => handleFormSubmit(e, "save");
  const handleContinue = async (e) => {
    e.preventDefault();

    if (activeTab === "profile") {
      const isValid = validateForm();
      if (!isValid) {
        alert("Please fill in all required fields correctly");
        return;
      }
      // Save profile data
      try {
        await handleFormSubmit(e, "save");
        setActiveTab("letters");
      } catch (error) {
        console.error("Error saving profile:", error);
      }
    } else if (activeTab === "letters") {
      // Add any validation for letter generation if needed
      setActiveTab("final");
    } else if (activeTab === "final") {
      if (!uploadedFile) {
        alert("Please upload a signed letter before continuing");
        return;
      }

      setIsLoading(true);
      try {
        const formData = new FormData();
        formData.append("signed_letter", uploadedFile);

        const response = await protectedApi.post(
          "/api/recommender/submit-letter/",
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
            withCredentials: true,
          }
        );

        if (response.status === 200 || response.status === 201) {
          alert("Letter submitted successfully!");
          navigate("/dashboard"); // or wherever you want to redirect after submission
        }
      } catch (error) {
        console.error("Error submitting letter:", error);
        alert("Failed to submit letter. Please try again.");
      } finally {
        setIsLoading(false);
      }
    }
  };
  const handlePrevious = (e) => navigate("/recommender");
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));

    // Update title when name changes
    if (name === "name") {
      document.title = `Recommender - ${value}`;
    }

    // Clear error for the field being changed
    if (errors[name]) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        [name]: undefined,
      }));
    }
  };

  const validateForm = () => {
    const newErrors = {};
    if (!formData.name) newErrors.name = "Name is required";
    if (!formData.email) {
      newErrors.email = "Email is required";
    } else if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(formData.email)) {
      newErrors.email = "Invalid email format";
    }
    if (
      formData.linkedinUrl &&
      !/^https?:\/\/([a-z]{2,3}\.)?linkedin.com\/.*$/.test(formData.linkedinUrl)
    ) {
      newErrors.linkedinUrl = "Please enter a valid LinkedIn URL";
    }
    if (!formData.relationship)
      newErrors.relationship = "Relationship is required";
    if (!formData.yearsKnown) newErrors.yearsKnown = "Years known is required";
    if (!formData.familiarity)
      newErrors.familiarity = "Familiarity level is required";

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = async (e, action = "save") => {
    e.preventDefault();
    const isValid = validateForm();

    if (!isValid) {
      alert("Please fill in all required fields correctly");
      return;
    }

    // Handle form submission logic here
    if (action === "continue") {
      navigate("/recommender/letters");
    } else {
      alert("Profile saved successfully!");
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, []);

  // Add letter generation form content
  const renderLetterGeneration = () => {
    if (activeTab !== "letters") return null;

    return (
      <div className={styles.letterGenerationForm}>
        <div className={styles.letterSection}>
          <h2>AI Recommendation Letter Generator</h2>
          <div className={styles.configureText}>
            Configure letter generation for {formData.name}
          </div>

          <div className={styles.endorsementSection}>
            <h3>Endorsed Item(s)</h3>
            <div className={styles.helperText}>
              Select your experience for endorsement
            </div>
            <button className={styles.addButton}>+ Add New Item</button>
          </div>

          <div className={styles.endorsementSection}>
            <h3>Endorsed Goal(s)</h3>
            <div className={styles.helperText}>
              Select your goal for endorsement
            </div>
            <button className={styles.addButton}>+ Add New Goal</button>
          </div>

          <div className={styles.endorsementSection}>
            <h3>Endorsed Endeavor(s)</h3>
            <div className={styles.helperText}>
              Select your endeavor for endorsement
            </div>
            <button className={styles.addButton}>+ Add New Endeavor</button>
          </div>

          <button className={styles.generateButton}>Generate Now</button>
        </div>

        <div className={styles.previewSection}>
          <h2>Generated Recommendation Letter(s)</h2>
          {/* Preview area for generated letters */}
        </div>
      </div>
    );
  };

  const [uploadedFile, setUploadedFile] = useState(null);

  const handleFileUpload = (event) => {
    const file = event.target.files[0];
    if (file) {
      setUploadedFile(file);
      // Here you would typically handle the file upload to your backend
    }
  };

  const renderFinalLetter = () => {
    return (
      <div className={styles.finalLetterContainer}>
        <div className={styles.uploadSection}>
          <p className={styles.uploadText}>
            Upload a signed version to finalize your recommendation letter
          </p>
          <label className={styles.uploadButton}>
            <span className={styles.uploadIcon}>↑</span>
            Upload
            <input
              type="file"
              onChange={handleFileUpload}
              accept=".pdf,.doc,.docx"
              style={{ display: "none" }}
            />
          </label>
        </div>
      </div>
    );
  };

  const renderButtons = () => {
    return (
      <div className={styles.buttoncontainer}>
        <Button variant="secondary" onClick={handlePrevious}>
          Previous
        </Button>
        <Button variant="secondary" onClick={handleSave}>
          Save
        </Button>
        <Button variant="primary" onClick={handleContinue} disabled={isLoading}>
          {isLoading
            ? "Processing..."
            : activeTab === "final"
            ? "Submit"
            : "Continue"}
        </Button>
      </div>
    );
  };

  return (
    <div className={styles.container}>
      <Header2 />
      <div className={styles.pageLayout}>
        <Sidebar />
        <div className={styles.mainContent}>
          <div className={styles.wrapper}>
            <h1 className={styles.title}>Recommender {formData.name}</h1>

            <div className={styles.formTabs}>
              <button
                className={`${styles.tab} ${
                  activeTab === "profile" ? styles.activeTab : ""
                }`}
                onClick={() => setActiveTab("profile")}
              >
                Profile
              </button>
              <button
                className={`${styles.tab} ${
                  activeTab === "letters" ? styles.activeTab : ""
                }`}
                onClick={() => setActiveTab("letters")}
              >
                Letter Generation
              </button>
              <button
                className={`${styles.tab} ${
                  activeTab === "final" ? styles.activeTab : ""
                }`}
                onClick={() => setActiveTab("final")}
              >
                Final Letter
              </button>
            </div>

            <div className={styles.formContainer}>
              {activeTab === "profile" && (
                <div className={styles.twoColumnLayout}>
                  {/* Left Column - Personal Information */}
                  <div className={styles.formSection}>
                    <h2 className={styles.sectionTitle}>
                      Personal Information
                    </h2>
                    <div className={styles.formGroup}>
                      <div className={styles.formRow}>
                        <div className={styles.dropdownContainer}>
                          <select
                            name="pronoun"
                            value={formData.pronoun}
                            onChange={handleChange}
                            className={styles.pronounDropdown}
                          >
                            <option value="" disabled>
                              Preferred Pronoun
                            </option>
                            <option value="Mr.">Mr.</option>
                            <option value="Mrs.">Mrs.</option>
                            <option value="Ms.">Ms.</option>
                            <option value="Dr.">Dr.</option>
                          </select>
                          {errors.pronoun && (
                            <div className={styles.errorText}>
                              {errors.pronoun}
                            </div>
                          )}
                        </div>
                        <div className={styles.formField}>
                          <input
                            type="text"
                            name="name"
                            placeholder="Name *"
                            value={formData.name}
                            onChange={handleChange}
                            className={styles.inputField}
                          />
                        </div>
                      </div>

                      <div className={styles.formField}>
                        <input
                          type="email"
                          name="email"
                          placeholder="Email"
                          value={formData.email}
                          onChange={handleChange}
                          className={styles.inputField}
                        />
                      </div>

                      <div className={styles.phoneNumberAmount}>
                        <div className={styles.phoneInputGroup}>
                          <CustomSelect
                            value={formData.countryCode}
                            onChange={handleChange}
                            options={COUNTRY_CODES}
                          />
                          <input
                            type="tel"
                            name="phoneNumber"
                            placeholder="Phone Number"
                            value={formData.phoneNumber}
                            onChange={handleChange}
                            className={styles.phoneInput}
                          />
                        </div>
                      </div>

                      <div className={styles.formField}>
                        <input
                          type="url"
                          name="linkedinUrl"
                          placeholder="LinkedIn URL"
                          value={formData.linkedinUrl}
                          onChange={handleChange}
                          className={styles.inputField}
                        />
                      </div>

                      <div className={styles.formField}>
                        <select
                          name="category"
                          value={formData.category}
                          onChange={handleChange}
                          className={styles.selectField}
                        >
                          <option value="" disabled>
                            Select Category
                          </option>
                          {CATEGORIES.map((cat) => (
                            <option key={cat.value} value={cat.value}>
                              {cat.label}
                            </option>
                          ))}
                        </select>
                      </div>

                      <div className={styles.formField}>
                        <input
                          type="text"
                          name="title"
                          placeholder="Title"
                          value={formData.title}
                          onChange={handleChange}
                          className={styles.inputField}
                        />
                      </div>

                      <div className={styles.formField}>
                        <input
                          type="text"
                          name="organization"
                          placeholder="Organization"
                          value={formData.organization}
                          onChange={handleChange}
                          className={styles.inputField}
                        />
                      </div>

                      <div className={styles.formField}>
                        <input
                          type="text"
                          name="department"
                          placeholder="Department"
                          value={formData.department}
                          onChange={handleChange}
                          className={styles.inputField}
                        />
                      </div>
                    </div>
                  </div>

                  {/* Right Column - Relationship to Applicant */}
                  <div className={styles.formSection}>
                    <h2 className={styles.sectionTitle}>
                      Relationship to Applicant
                    </h2>
                    <div className={styles.formGroup}>
                      <div className={styles.formField}>
                        <input
                          type="text"
                          name="relationship"
                          placeholder="Relationship to the Applicant"
                          value={formData.relationship}
                          onChange={handleChange}
                          className={styles.inputField}
                        />
                      </div>

                      <div className={styles.formField}>
                        <input
                          type="number"
                          name="yearsKnown"
                          placeholder="Years Known"
                          value={formData.yearsKnown}
                          onChange={handleChange}
                          className={styles.inputField}
                        />
                      </div>

                      <div className={styles.radioGroup}>
                        <label>
                          How well does the recommender know the applicant?
                        </label>
                        <div className={styles.radioOptions}>
                          <label>
                            <input
                              type="radio"
                              name="familiarity"
                              value="moderately"
                              checked={formData.familiarity === "moderately"}
                              onChange={handleChange}
                            />
                            Moderately familiar
                          </label>
                          <label>
                            <input
                              type="radio"
                              name="familiarity"
                              value="familiar"
                              checked={formData.familiarity === "familiar"}
                              onChange={handleChange}
                            />
                            Familiar
                          </label>
                          <label>
                            <input
                              type="radio"
                              name="familiarity"
                              value="very"
                              checked={formData.familiarity === "very"}
                              onChange={handleChange}
                            />
                            Very Familiar
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
              {activeTab === "letters" && renderLetterGeneration()}
              {activeTab === "final" && renderFinalLetter()}
              {renderButtons()}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default RecommenderConfig;
