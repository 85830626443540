import { protectedApi } from "./api";

export const fetchExistingData = async (endpoint) => {
  try {
    const response = await protectedApi.get(endpoint);
    console.log(`[GET] ${endpoint} response:`, response.data);

    if (response.data?.results?.length > 0) {
      const activeItems = response.data.results.filter(
        (item) => !item.is_deleted
      );
      return activeItems;
    }
    return [];
  } catch (error) {
    console.error(`[GET] Error fetching data from ${endpoint}:`, error);
    throw error;
  }
};

export const handleFormSubmit = async ({
  endpoint,
  items,
  deletedItems,
  transformItem,
  applicationId,
}) => {
  try {
    if (!applicationId) {
      throw new Error("No active application found");
    }

    // Filter out active items (not deleted)
    const activeItems = items.filter((item) => !deletedItems.has(item.id));

    // Prepare items for submission
    const itemsToSubmit = activeItems.map((item) => transformItem(item));

    // Separate items into updates and creates
    const itemsToUpdate = itemsToSubmit.filter((p) => p.id);
    const itemsToCreate = itemsToSubmit.filter((p) => !p.id);

    const results = {
      created: [],
      updated: [],
      deleted: [],
    };

    // Handle updates
    if (itemsToUpdate.length > 0) {
      const updateResponse = await protectedApi.patch(endpoint, itemsToUpdate, {
        headers: {
          "Content-Type": "application/json",
          "X-Application-ID": applicationId,
        },
      });
      results.updated = updateResponse.data;
    }

    // Handle creates
    if (itemsToCreate.length > 0) {
      const createResponse = await protectedApi.post(endpoint, itemsToCreate, {
        headers: {
          "Content-Type": "application/json",
          "X-Application-ID": applicationId,
          "X-CSRFToken": document.cookie.match(/csrftoken=([\w-]+)/)?.[1] || "",
        },
      });
      results.created = createResponse.data;
    }

    // Handle deletions
    if (deletedItems.size > 0) {
      const deletePromises = Array.from(deletedItems).map((itemId) =>
        protectedApi.delete(`${endpoint}${itemId}/`, {
          headers: {
            "Content-Type": "application/json",
            "X-Application-ID": applicationId,
          },
        })
      );
      await Promise.all(deletePromises);
      results.deleted = Array.from(deletedItems);
    }

    return results;
  } catch (error) {
    console.error("Error submitting form:", error);
    throw error;
  }
};
