import React from "react";
import { useNavigate } from "react-router-dom";
import styles from "./Cart.module.css";
import Header2 from "../common/Header2";
import Sidebar from "../common/Sidebar";
import Button from "../common/Button";

function Cart({ cart = [], removeFromCart }) {
  const navigate = useNavigate();

  const calculateTotal = () => {
    return cart.reduce((total, item) => total + item.price, 0);
  };

  return (
    <div className={styles.container}>
      <Header2 />
      <div className={styles.pageLayout}>
        <Sidebar />
        <div className={styles.mainContent}>
          <h2 className={styles.title}>Shopping Cart</h2>
          {!cart || cart.length === 0 ? (
            <div className={styles.emptyCart}>
              <p className={styles.emptyMessage}>Your cart is empty</p>
              <Button
                className={styles.returnButton}
                onClick={() => navigate("/plans")}
              >
                Return to Plans
              </Button>
            </div>
          ) : (
            <>
              <div className={styles.cartItems}>
                {cart.map((item, index) => (
                  <div key={index} className={styles.cartItem}>
                    <div className={styles.itemInfo}>
                      <h3 className={styles.itemName}>{item.name}</h3>
                      <p className={styles.itemQuantity}>
                        Quantity: {item.quantity}
                      </p>
                    </div>
                    <div className={styles.itemActions}>
                      <p className={styles.itemPrice}>${item.price}</p>
                      <Button
                        className={styles.removeButton}
                        onClick={() => removeFromCart(index)}
                      >
                        Remove
                      </Button>
                    </div>
                  </div>
                ))}
              </div>
              <div className={styles.cartSummary}>
                <div className={styles.summaryDetails}>
                  <div className={styles.summaryRow}>
                    <span>Subtotal:</span>
                    <span>${calculateTotal()}</span>
                  </div>
                  <div className={styles.summaryRow}>
                    <span>Total:</span>
                    <span className={styles.totalAmount}>
                      ${calculateTotal()}
                    </span>
                  </div>
                </div>
                <Button
                  className={styles.checkoutButton}
                  onClick={() => navigate("/checkout")}
                >
                  Proceed to Checkout
                </Button>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
}

export default Cart;
