import React, { useState, useEffect } from "react";
import styles from "./Education.module.css";
import logo from "../../assets/images/logo.svg";
import { useNavigate } from "react-router-dom";
import config from "../../config.js";
import Sidebar from "../common/Sidebar";
import Button from "../common/Button";
import axios from "axios";
import Stepper from "../common/ProgressBar.js";
import { protectedApi } from "../../services/api.js";
import {
  COUNTRIES,
  DEGREES,
  LEARNING_TYPE,
} from "../../constants/dropdownConsts.js";
import CustomDropdown from "../common/Dropdown.js";
import FileUpload from "../common/FileUpload.js";
import { useCrudOperations } from "../../services/useCrudOperations.js";
import Header2 from "../common/Header2.js";
const initialEducationState = {
  school_name: "",
  school_website: "",
  school_country: "",
  school_state: "",
  school_city: "",
  learning_type: "",
  degree: "",
  major: "",
  start_date: "",
  end_date: "",
  is_current: false,
  total_gpa: "",
  max_gpa: "",
  courses: [],
  diploma_en: null,
  transcript_en: null,
  diploma_cn: null,
  transcript_cn: null,
};

const validateEducation = (edu) => {
  const errors = {};

  // Required field validation
  if (!edu.school_name) errors.school_name = "School name is required";
  if (!edu.school_website) {
    errors.school_website = "School website is required";
  } else {
    // Add http:// if not present
    const url = edu.school_website.startsWith("http")
      ? edu.school_website
      : `https://${edu.school_website}`;
    try {
      new URL(url);
    } catch {
      errors.school_website = "Please enter a valid URL (e.g., www.school.edu)";
    }
  }
  if (!edu.school_country) errors.school_country = "Country is required";
  if (!edu.school_state) errors.school_state = "State is required";
  if (!edu.school_city) errors.school_city = "City is required";

  // Date validation
  if (!edu.start_date) {
    errors.start_date = "Start date is required";
  } else {
    const startDate = new Date(edu.start_date);
    if (isNaN(startDate.getTime())) {
      errors.start_date = "Please enter a valid date";
    }
  }

  if (!edu.end_date && !edu.is_current) {
    errors.end_date = "End date is required if not currently studying";
  } else if (edu.end_date) {
    const endDate = new Date(edu.end_date);
    const startDate = new Date(edu.start_date);
    if (isNaN(endDate.getTime())) {
      errors.end_date = "Please enter a valid date";
    } else if (endDate < startDate) {
      errors.end_date = "End date cannot be before start date";
    }
  }

  // Academic info validation
  if (!edu.degree) errors.degree = "Degree is required";
  if (!edu.major) errors.major = "Major is required";
  if (!edu.learning_type) errors.learning_type = "Learning type is required";

  // GPA validation
  if (!edu.total_gpa) {
    errors.total_gpa = "Total GPA is required";
  } else {
    const totalGpa = parseFloat(edu.total_gpa);
    if (isNaN(totalGpa) || totalGpa < 0) {
      errors.total_gpa = "Please enter a valid GPA (must be a positive number)";
    }
  }

  if (!edu.max_gpa) {
    errors.max_gpa = "Max GPA is required";
  } else {
    const maxGpa = parseFloat(edu.max_gpa);
    const totalGpa = parseFloat(edu.total_gpa);
    if (isNaN(maxGpa) || maxGpa <= 0) {
      errors.max_gpa =
        "Please enter a valid max GPA (must be a positive number)";
    } else if (totalGpa > maxGpa) {
      errors.max_gpa = "Max GPA must be greater than or equal to total GPA";
    }
  }

  // Courses validation
  if (!edu.courses || edu.courses.length === 0) {
    errors.courses = "At least one course is required";
  } else {
    const invalidCourses = edu.courses.filter(
      (course) => !course || course.trim() === ""
    );
    if (invalidCourses.length > 0) {
      errors.courses = "All courses must have valid names";
    }
  }

  // File validation
  const validateFile = (file, fieldName) => {
    if (file && file.file) {
      const allowedTypes = ["application/pdf", "image/jpeg", "image/png"];
      const fileType = file.type;
      if (!allowedTypes.includes(fileType)) {
        errors[fieldName] = "File must be PDF, JPG, or PNG";
      }
      // Add file size validation (e.g., max 5MB)
      const maxSize = 5 * 1024 * 1024; // 5MB in bytes
      if (file.size > maxSize) {
        errors[fieldName] = "File size must be less than 5MB";
      }
    }
  };

  validateFile(edu.diploma_en, "diploma_en");
  validateFile(edu.transcript_en, "transcript_en");
  validateFile(edu.diploma_cn, "diploma_cn");
  validateFile(edu.transcript_cn, "transcript_cn");

  return { isValid: Object.keys(errors).length === 0, errors };
};

function EducationInfoPage() {
  const navigate = useNavigate();
  const [education, setEducation] = useState([initialEducationState]);
  const [errors, setErrors] = useState([{}]);
  const [loading, setLoading] = useState(false);
  const [openEducations, setOpenEducations] = useState([true]);

  useEffect(() => {
    const fetchExistingEducation = async () => {
      try {
        const response = await protectedApi.get("/api/academichistory/");
        console.log("[GET] Academic History response:", response.data);

        if (
          response.data &&
          response.data.results &&
          response.data.results.length > 0
        ) {
          const currentEducations = response.data.results;
          console.log("[GET] Current Education data:", currentEducations);

          // Pre-fill education data if exists
          const educationData = currentEducations.map((edu) => ({
            id: edu.id,
            school_name: edu.school_name || "",
            school_website: edu.school_website || "",
            school_country: edu.school_country || "",
            school_state: edu.school_state || "",
            school_city: edu.school_city || "",
            learning_type: edu.learning_type || "",
            degree: edu.degree || "",
            major: edu.major || "",
            start_date: edu.start_date || "",
            end_date: edu.end_date || "",
            is_current: edu.is_current || false,
            total_gpa: edu.total_gpa || "",
            max_gpa: edu.max_gpa || "",
            courses: edu.courses || [],
            diploma_en: edu.diploma_en
              ? { name: edu.diploma_en.split("/").pop(), url: edu.diploma_en }
              : null,
            transcript_en: edu.transcript_en
              ? {
                  name: edu.transcript_en.split("/").pop(),
                  url: edu.transcript_en,
                }
              : null,
            diploma_cn: edu.diploma_cn
              ? { name: edu.diploma_cn.split("/").pop(), url: edu.diploma_cn }
              : null,
            transcript_cn: edu.transcript_cn
              ? {
                  name: edu.transcript_cn.split("/").pop(),
                  url: edu.transcript_cn,
                }
              : null,
          }));

          // Update education state with all records
          setEducation(educationData);

          // Update errors and openEducations states to match the number of records
          setErrors(new Array(educationData.length).fill({}));
          setOpenEducations(new Array(educationData.length).fill(true));
        }
      } catch (error) {
        console.error("[GET] Error fetching education history:", error);
      }
    };

    fetchExistingEducation();
  }, []);

  const handleChange = (e, index) => {
    if (!e) return;

    const { name, value, type, checked } = e.target || e;
    setEducation((prevEducation) => {
      const updatedEducation = [...prevEducation];
      updatedEducation[index] = {
        ...updatedEducation[index],
        [name]: type === "checkbox" ? checked : value,
      };
      return updatedEducation;
    });
  };

  const validateEducation = (edu) => {
    const errors = {};

    if (!edu.school_name) errors.school_name = "School name is required";
    if (!edu.school_website)
      errors.school_website = "School website is required";
    if (!edu.school_country) errors.school_country = "Country is required";
    if (!edu.school_state) errors.school_state = "State is required";
    if (!edu.school_city) errors.school_city = "City is required";
    if (!edu.start_date) errors.start_date = "Start date is required";
    if (!edu.end_date) errors.end_date = "End date is required";
    if (!edu.degree) errors.degree = "Degree is required";
    if (!edu.major) errors.major = "Major is required";
    if (!edu.learning_type) errors.learning_type = "Learning type is required";
    if (!edu.total_gpa) errors.total_gpa = "Total GPA is required";
    if (!edu.max_gpa) errors.max_gpa = "Max GPA is required";
    if (!edu.courses || edu.courses.length === 0)
      errors.courses = "At least one course is required";

    return { isValid: Object.keys(errors).length === 0, errors };
  };

  const validateForm = () => {
    const newErrors = education.map((edu) => {
      const { errors } = validateEducation(edu);
      return errors;
    });

    setErrors(newErrors);
    const isValid = newErrors.every(
      (eduErrors) => Object.keys(eduErrors).length === 0
    );

    if (!isValid) {
      const errorMessages = newErrors
        .map((eduErrors, index) => {
          const errorList = Object.entries(eduErrors)
            .map(([field, message]) => `${field}: ${message}`)
            .join("\n");
          return errorList ? `Education ${index + 1}:\n${errorList}` : null;
        })
        .filter(Boolean)
        .join("\n\n");

      alert(`Please fix the following errors:\n\n${errorMessages}`);
    }

    return isValid;
  };

  const handleFormSubmit = async (e, action = "save") => {
    e.preventDefault();

    // Validate all education entries
    const validationResults = education.map(validateEducation);
    const hasErrors = validationResults.some((result) => !result.isValid);
    if (hasErrors) {
      const newErrors = validationResults.map((result) => result.errors);
      setErrors(newErrors);
      alert("Please fill in all required fields correctly");
      return;
    }

    setLoading(true);
    try {
      const applicationId = localStorage.getItem("applicationId");
      if (!applicationId) {
        throw new Error("No active application found");
      }

      // Separate new and existing educations
      const newEducations = education.filter((edu) => !edu.id);
      const existingEducations = education.filter((edu) => edu.id);

      // Handle new educations
      if (newEducations.length > 0) {
        const newEducationData = newEducations.map((edu) => ({
          school_name: edu.school_name,
          school_website: edu.school_website.startsWith("http")
            ? edu.school_website
            : `https://${edu.school_website}`,
          school_country: edu.school_country,
          school_state: edu.school_state,
          school_city: edu.school_city,
          start_date: edu.start_date,
          end_date: edu.end_date || null,
          is_current: Boolean(edu.is_current),
          degree: edu.degree,
          major: edu.major,
          learning_type: edu.learning_type,
          total_gpa: parseFloat(edu.total_gpa),
          max_gpa: parseFloat(edu.max_gpa),
          courses: Array.isArray(edu.courses) ? edu.courses : [],
        }));

        await protectedApi.post("/api/academichistory/", newEducationData, {
          headers: {
            "Content-Type": "application/json",
            "X-Application-ID": applicationId,
          },
        });
      }

      // Handle existing educations
      if (existingEducations.length > 0) {
        const updatedEducationData = existingEducations.map((edu) => ({
          id: edu.id,
          school_name: edu.school_name,
          school_website: edu.school_website.startsWith("http")
            ? edu.school_website
            : `https://${edu.school_website}`,
          school_country: edu.school_country,
          school_state: edu.school_state,
          school_city: edu.school_city,
          start_date: edu.start_date,
          end_date: edu.end_date || null,
          is_current: Boolean(edu.is_current),
          degree: edu.degree,
          major: edu.major,
          learning_type: edu.learning_type,
          total_gpa: parseFloat(edu.total_gpa),
          max_gpa: parseFloat(edu.max_gpa),
          courses: Array.isArray(edu.courses) ? edu.courses : [],
        }));

        await protectedApi.patch(
          "/api/academichistory/",
          updatedEducationData,
          {
            headers: {
              "Content-Type": "application/json",
              "X-Application-ID": applicationId,
            },
          }
        );
      }

      if (action === "continue") {
        navigate("/scientific-project");
      } else {
        alert("Education information saved successfully!");
      }
    } catch (error) {
      console.error("Error submitting education:", error);
      alert(error.message || "Something went wrong");
    } finally {
      setLoading(false);
    }
  };

  const handleSave = async () => {
    try {
      const formattedData = education.map((edu) => {
        // Start with basic education data
        const eduData = {
          id: edu.id, // Required for PATCH
          school_name: edu.school_name,
          school_website: edu.school_website,
          school_country: edu.school_country,
          school_state: edu.school_state,
          school_city: edu.school_city,
          learning_type: edu.learning_type,
          degree: edu.degree,
          major: edu.major,
          start_date: edu.start_date,
          end_date: edu.end_date,
          is_current: edu.is_current,
          total_gpa: edu.total_gpa,
          max_gpa: edu.max_gpa,
          courses: edu.courses,
        };

        // Only include file fields if they've changed
        ["diploma_en", "diploma_cn", "transcript_en", "transcript_cn"].forEach(
          (fieldName) => {
            if (edu[fieldName] && edu[fieldName].file) {
              // If it's a new file upload
              eduData[fieldName] = edu[fieldName];
            } else if (edu[fieldName] === null) {
              // If file was removed
              eduData[fieldName] = null;
            }
            // If file wasn't changed, don't include in the PATCH data
          }
        );

        return eduData;
      });

      const response = await protectedApi.patch(
        "/api/academichistory/",
        formattedData
      );

      if (response.status === 200) {
        // Handle success
        console.log("Education records updated successfully");
      }
    } catch (error) {
      console.error("Error updating education records:", error);
      // Handle error appropriately
    }
  };

  const handleContinue = (e) => handleFormSubmit(e, "continue");

  const handleDeleteEducation = async (index) => {
    const eduToDelete = education[index];

    if (eduToDelete.id) {
      try {
        await protectedApi.delete(`/api/academichistory/${eduToDelete.id}/`, {
          headers: {
            "Content-Type": "application/json",
            "X-Application-ID": localStorage.getItem("applicationId"),
          },
        });
      } catch (error) {
        console.error("Error deleting education:", error);
        alert("Failed to delete education");
        return;
      }
    }

    setEducation((prevEducation) =>
      prevEducation.filter((_, idx) => idx !== index)
    );
    setErrors((prevErrors) => prevErrors.filter((_, idx) => idx !== index));
  };

  const handleFileChange = (event, index, fieldName) => {
    const file = event.target.files[0];
    if (!file) return;

    // Validate file type
    if (!["application/pdf", "image/jpeg", "image/png"].includes(file.type)) {
      alert("Please upload a PDF, JPG, or PNG file");
      return;
    }

    setEducation((prev) => {
      const updated = [...prev];
      updated[index] = {
        ...updated[index],
        [fieldName]: {
          file: file, // Store the actual File object
          name: file.name,
          type: file.type,
        },
      };
      return updated;
    });
  };

  const handleRemoveFile = (index, fieldName) => {
    setEducation((prev) => {
      const updated = [...prev];
      updated[index] = {
        ...updated[index],
        [fieldName]: null,
      };
      return updated;
    });
  };

  const handleCourseKeyDown = (e, index) => {
    if (e.key === "Enter") {
      e.preventDefault();
      const inputValue = e.target.value.trim();
      if (!inputValue) return;

      setEducation((prev) => {
        const updated = [...prev];
        if (!updated[index].courses) {
          updated[index].courses = [];
        }
        if (!updated[index].courses.includes(inputValue)) {
          updated[index].courses = [...updated[index].courses, inputValue];
        }
        return updated;
      });
      e.target.value = "";
    }
  };

  const removeCourse = (eduIndex, courseIndex) => {
    setEducation((prev) => {
      const updated = [...prev];
      updated[eduIndex].courses = updated[eduIndex].courses.filter(
        (_, i) => i !== courseIndex
      );
      return updated;
    });
  };

  const addNewEducation = () => {
    setEducation((prev) => [...prev, { ...initialEducationState }]);
    setOpenEducations((prev) => [...prev, true]);
    setErrors((prev) => [...prev, {}]);
  };

  const handleLogout = () => {
    console.log("Logged out");
    navigate("/login");
  };

  const handlePrevious = () => {
    navigate("/");
  };

  const toggleProject = (index) => {
    setOpenEducations((prev) => {
      const newState = [...prev];
      newState[index] = !newState[index];
      return newState;
    });
  };

  const handleDeleteProject = (indexToDelete) => {
    handleDeleteEducation(indexToDelete);
    setOpenEducations((prev) =>
      prev.filter((_, index) => index !== indexToDelete)
    );
  };

  const handleSubmit = async (edu, action) => {
    const formData = new FormData();

    // Add regular fields
    Object.keys(edu).forEach((key) => {
      if (
        ![
          "diploma_en",
          "diploma_cn",
          "transcript_en",
          "transcript_cn",
        ].includes(key)
      ) {
        formData.append(key, edu[key]);
      }
    });

    // Add files if they exist and have changed
    if (edu.diploma_en?.file) {
      const file = new File([edu.diploma_en.file], edu.diploma_en.name, {
        type: edu.diploma_en.type,
      });
      formData.append("diploma_en", file);
    }
    // Repeat for other file fields

    try {
      if (action === "patch") {
        await protectedApi.patch(`/api/academichistory/${edu.id}/`, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        });
      }
    } catch (error) {
      console.error("Error:", error);
      throw error;
    }
  };

  return (
    <div className={styles.container}>
      <Header2 />
      <div className={styles.pageLayout}>
        <Sidebar />
        <div className={styles.createProfile}>
          <div className={styles.wrapper}>
            <h1 className={styles.createYourProfile}>Create Your Profile</h1>
            {loading && (
              <div className={styles.loadingIndicator}>Loading...</div>
            )}
            <form onSubmit={handleFormSubmit}>
              <div className={styles.formContainer}>
                {education.map((edu, index) => (
                  <div key={index} className={styles.formSection}>
                    <div className={styles.projectHeader}>
                      <h2 className={styles.projectTitle}>
                        {edu.schoolName || `Education History 1`}
                      </h2>
                      <div className={styles.headerActions}>
                        {index > 0 && (
                          <svg
                            className={styles.deleteIcon}
                            onClick={(e) => {
                              e.stopPropagation();
                              handleDeleteProject(index);
                            }}
                            viewBox="0 0 24 24"
                            fill="none"
                            stroke="currentColor"
                          >
                            <path
                              d="M6 7V18C6 19.1046 6.89543 20 8 20H16C17.1046 20 18 19.1046 18 18V7M6 7H5M6 7H8M18 7H19M18 7H16M10 11V16M14 11V16M8 7V5C8 3.89543 8.89543 3 10 3H14C15.1046 3 16 3.89543 16 5V7M8 7H16"
                              strokeWidth="2"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                          </svg>
                        )}
                        <svg
                          className={`${styles.dropdownIcon} ${
                            openEducations[index] ? styles.open : ""
                          }`}
                          onClick={() => toggleProject(index)}
                          viewBox="0 0 24 24"
                          fill="none"
                          stroke="currentColor"
                        >
                          <path
                            d="M6 9l6 6 6-6"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </svg>
                      </div>
                    </div>

                    <div
                      className={`${styles.projectContent} ${
                        openEducations[index] ? styles.open : ""
                      }`}
                    >
                      {index === 0 && (
                        <div className={styles.sectionHeader}>
                          <Stepper
                            currentStep={2}
                            totalSteps={7}
                            progressWidth={28}
                          />
                        </div>
                      )}

                      {/* Original Input Fields */}
                      <div className={styles.formRow}>
                        <div className={styles.inputWrapper}>
                          <input
                            type="text"
                            name="school_name"
                            placeholder="School Name"
                            value={edu.school_name}
                            onChange={(e) => handleChange(e, index)}
                            className={`${styles.inputField} ${
                              errors[index]?.school_name
                                ? styles.errorInput
                                : ""
                            }`}
                          />
                          {errors[index]?.school_name && (
                            <span className={styles.errorMessage}>
                              {errors[index].school_name}
                            </span>
                          )}
                        </div>
                        <div className={styles.inputWrapper}>
                          <input
                            type="url"
                            name="school_website"
                            placeholder="School Website"
                            value={edu.school_website}
                            onChange={(e) => handleChange(e, index)}
                            className={`${styles.inputField} ${
                              errors[index]?.school_website
                                ? styles.errorInput
                                : ""
                            }`}
                          />
                          {errors[index]?.school_website && (
                            <span className={styles.errorMessage}>
                              {errors[index].school_website}
                            </span>
                          )}
                        </div>
                      </div>

                      <div className={styles.formRow}>
                        <div className={styles.inputWrapper}>
                          <CustomDropdown
                            name="school_country"
                            value={edu.school_country}
                            onChange={(e) => handleChange(e, index)}
                            options={COUNTRIES}
                            placeholder="Select Country"
                            error={errors[index]?.school_country}
                            required
                          />
                        </div>
                        <div className={styles.inputWrapper}>
                          <input
                            type="text"
                            name="school_state"
                            placeholder="School State"
                            value={edu.school_state}
                            onChange={(e) => handleChange(e, index)}
                            className={styles.inputField}
                          />
                          {errors[index]?.school_state && (
                            <span className={styles.errorMessage}>
                              {errors[index].school_state}
                            </span>
                          )}
                        </div>
                      </div>

                      <div className={styles.formRow}>
                        <div className={styles.inputWrapper}>
                          <input
                            type="text"
                            name="school_city"
                            placeholder="School City"
                            value={edu.school_city}
                            onChange={(e) => handleChange(e, index)}
                            className={`${styles.inputField} ${
                              errors[index]?.school_city
                                ? styles.errorInput
                                : ""
                            }`}
                          />
                          {errors[index]?.school_city && (
                            <span className={styles.errorMessage}>
                              {errors[index].school_city}
                            </span>
                          )}
                        </div>
                        <div className={styles.inputWrapper}>
                          <CustomDropdown
                            name="learning_type"
                            value={edu.learning_type}
                            onChange={(e) => handleChange(e, index)}
                            options={LEARNING_TYPE}
                            placeholder="Select Learning Type"
                            error={errors[index]?.learning_type}
                            required
                          />
                        </div>
                      </div>

                      <div className={styles.formRow}>
                        <div className={styles.inputWrapper}>
                          <CustomDropdown
                            name="degree"
                            value={edu.degree}
                            onChange={(e) => handleChange(e, index)}
                            options={DEGREES}
                            placeholder="Select Degree"
                            error={errors[index]?.degree}
                            required
                          />
                        </div>
                        <div className={styles.inputWrapper}>
                          <input
                            type="text"
                            name="major"
                            placeholder="Major"
                            value={edu.major}
                            onChange={(e) => handleChange(e, index)}
                            className={`${styles.inputField} ${
                              errors[index]?.major ? styles.errorInput : ""
                            }`}
                          />
                          {errors[index]?.major && (
                            <span className={styles.errorMessage}>
                              {errors[index].major}
                            </span>
                          )}
                        </div>
                      </div>

                      <div className={styles.formRow}>
                        <div className={styles.inputWrapper}>
                          <label className={styles.inputLabel}>
                            Start Date
                          </label>
                          <input
                            type="date"
                            name="start_date"
                            value={edu.start_date}
                            onChange={(e) => handleChange(e, index)}
                            className={`${styles.inputField} ${
                              errors[index]?.start_date ? styles.errorInput : ""
                            }`}
                          />
                          {errors[index]?.start_date && (
                            <span className={styles.errorMessage}>
                              {errors[index].start_date}
                            </span>
                          )}
                        </div>
                        <div className={styles.inputWrapper}>
                          <label className={styles.inputLabel}>End Date</label>
                          <input
                            type="date"
                            name="end_date"
                            value={edu.end_date}
                            disabled={edu.is_current}
                            onChange={(e) => handleChange(e, index)}
                            className={`${styles.inputField} ${
                              errors[index]?.end_date ? styles.errorInput : ""
                            }`}
                          />
                          {errors[index]?.end_date && (
                            <span className={styles.errorMessage}>
                              {errors[index].end_date}
                            </span>
                          )}
                          <div className={styles.checkboxField}>
                            <input
                              type="checkbox"
                              id={`is_current-${index}`}
                              name="is_current"
                              checked={edu.is_current}
                              onChange={(e) => handleChange(e, index)}
                              className={styles.checkboxField}
                            />
                            <label htmlFor={`is_current-${index}`}>
                              Current
                            </label>
                          </div>
                        </div>
                      </div>

                      <div className={styles.formRow}>
                        <div className={styles.inputWrapper}>
                          <input
                            type="number"
                            step="0.01"
                            min="0"
                            max="4.0"
                            name="total_gpa"
                            placeholder="Your GPA"
                            value={edu.total_gpa}
                            onChange={(e) => handleChange(e, index)}
                            className={`${styles.inputField} ${
                              errors[index]?.total_gpa ? styles.errorInput : ""
                            }`}
                          />
                          {errors[index]?.total_gpa && (
                            <span className={styles.errorMessage}>
                              {errors[index].total_gpa}
                            </span>
                          )}
                        </div>
                        <div className={styles.inputWrapper}>
                          <input
                            type="number"
                            step="0.01"
                            min="0"
                            max="4.0"
                            name="max_gpa"
                            placeholder="Highest GPA"
                            value={edu.max_gpa}
                            onChange={(e) => handleChange(e, index)}
                            className={`${styles.inputField} ${
                              errors[index]?.max_gpa ? styles.errorInput : ""
                            }`}
                          />
                          {errors[index]?.max_gpa && (
                            <span className={styles.errorMessage}>
                              {errors[index].max_gpa}
                            </span>
                          )}
                        </div>
                      </div>

                      <div className={styles.formRow}>
                        <div className={styles.inputWrapper}>
                          <input
                            type="text"
                            name="course_input"
                            placeholder="Add a course and press Enter"
                            onKeyDown={(e) => handleCourseKeyDown(e, index)}
                            className={`${styles.inputField} ${
                              errors[index]?.courses ? styles.errorInput : ""
                            }`}
                          />
                          {errors[index]?.courses && (
                            <span className={styles.errorMessage}>
                              {errors[index].courses}
                            </span>
                          )}
                        </div>
                      </div>
                      <div className={styles.courseTags}>
                        {edu.courses.map((course, courseIndex) => (
                          <div key={courseIndex} className={styles.courseTag}>
                            {course}
                            <span
                              className={styles.deleteCourse}
                              onClick={() => removeCourse(index, courseIndex)}
                            >
                              ×
                            </span>
                          </div>
                        ))}
                      </div>

                      <div className={styles.fileUploadSection}>
                        {/* English Documents Row */}
                        <div className={styles.fileUploadRow}>
                          {/* English Diploma */}
                          <div className={styles.fileUploadItem}>
                            <div className={styles.fileUploadContainer}>
                              <input
                                type="file"
                                accept=".pdf,.jpg,.png"
                                onChange={(e) =>
                                  handleFileChange(e, index, "diploma_en")
                                }
                                className={`${styles.fileInput} ${
                                  errors[index]?.diploma_en
                                    ? styles.errorInput
                                    : ""
                                }`}
                                id={`diploma_en_${index}`}
                              />
                              <label
                                htmlFor={`diploma_en_${index}`}
                                className={`${styles.fileInputLabel} ${
                                  errors[index]?.diploma_en
                                    ? styles.errorInput
                                    : ""
                                }`}
                              >
                                {edu.diploma_en?.name ||
                                  "Upload Diploma in English *"}
                              </label>
                              {edu.diploma_en?.name && (
                                <div className={styles.fileItem}>
                                  <span>{edu.diploma_en.name}</span>
                                  <button
                                    type="button"
                                    onClick={() =>
                                      handleRemoveFile(index, "diploma_en")
                                    }
                                    className={styles.removeFile}
                                  >
                                    ×
                                  </button>
                                </div>
                              )}
                              {errors[index]?.diploma_en && (
                                <span className={styles.errorMessage}>
                                  {errors[index].diploma_en}
                                </span>
                              )}
                            </div>
                          </div>

                          {/* English Transcript */}
                          <div className={styles.fileUploadItem}>
                            <div className={styles.fileUploadContainer}>
                              <input
                                type="file"
                                accept=".pdf,.jpg,.png"
                                onChange={(e) =>
                                  handleFileChange(e, index, "transcript_en")
                                }
                                className={styles.fileInput}
                                id={`transcript_en_${index}`}
                              />
                              <label
                                htmlFor={`transcript_en_${index}`}
                                className={styles.fileInputLabel}
                              >
                                Upload Transcript in English
                              </label>
                              <div className={styles.uploadedFiles}>
                                {edu.transcript_en?.name && (
                                  <div className={styles.fileItem}>
                                    <span>{edu.transcript_en.name}</span>
                                    <button
                                      type="button"
                                      onClick={() =>
                                        handleRemoveFile(index, "transcript_en")
                                      }
                                      className={styles.removeFile}
                                      aria-label="Remove file"
                                    >
                                      ×
                                    </button>
                                  </div>
                                )}
                              </div>
                              {errors[index]?.transcript_en && (
                                <span className={styles.errorMessage}>
                                  {errors[index].transcript_en}
                                </span>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}

                {/* Form Actions - Outside the projects loop */}
                <div className={styles.formActions}>
                  <button
                    type="button"
                    className={styles.secondaryButton}
                    onClick={addNewEducation}
                  >
                    Add Another Education Info
                  </button>
                  <div className={styles.navButtons}>
                    <Button variant="secondary" onClick={handlePrevious}>
                      Previous
                    </Button>
                    <Button variant="secondary" onClick={handleSave}>
                      Save
                    </Button>
                    <Button variant="primary" onClick={handleContinue}>
                      Continue
                    </Button>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

export default EducationInfoPage;
