import React from "react";
import { useNavigate } from "react-router-dom";
import styles from "./recommender.module.css";
import Sidebar from "../common/Sidebar";
import Header2 from "../common/Header2";

function Recommender() {
  const navigate = useNavigate();

  return (
    <div className={styles.container}>
      <Header2 />
      <div className={styles.pageLayout}>
        <Sidebar />
        <div className={styles.mainContent}>
          <div className={styles.wrapper}>
            <h1 className={styles.title}>Recommendation Letter</h1>
            <div className={styles.recommenderContainer}>
              <div className={styles.recommenderBox}>
                <button
                  className={styles.addButton}
                  onClick={() => navigate("/recommender-config")}
                >
                  <span className={styles.plusIcon}>+</span>
                  <span>Add Recommender</span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Recommender;
