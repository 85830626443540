import React, { useState } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import LandingPage from "./components/LandingPage";
import LogInPage from "./components/LogInPage";
import RegistrationPage from "./components/RegistrationPage";
import ForgotPassword from "./components/ForgetPasswordPage";
import VerificationPage from "./components/VerificationPage";
import PersonalInfo from "./components/dashboard/PersonalInfoPage";
import ProjectInfo from "./components/dashboard/ProjectInfoPage";
import EmploymentInfo from "./components/dashboard/EmploymentInfoPage";
import EducationInfo from "./components/dashboard/Education";
import ScientificProject from "./components/dashboard/ScientificProject";
import FuturePlan from "./components/dashboard/FuturePlan";
import Evidence from "./components/dashboard/Evidence";
import RFE from "./components/dashboard/RFE";
import Dashboard from "./components/dashboard/Dashboard";
import ProposedEndeavor from "./components/features/proposed_endeavor";
import RecommenderConfig from "./components/features/recommender_config";
import Recommender from "./components/features/recommender";
import PetitionLetter from "./components/features/petition_letter";
import Plans from "./components/features/plans";
import Cart from "./components/features/Cart";
import RfeAnalysis from "./components/features/rfe_analysis";
import RfeResponse from "./components/features/rfe_response";
import AllPlans from "./components/dashboard/AllPlans";
// import "./styles/global.css";

function App() {
  const [cart, setCart] = useState([]);

  const handleAddToCart = (item) => {
    setCart([...cart, item]);
  };

  const handleRemoveFromCart = (index) => {
    const newCart = cart.filter((_, i) => i !== index);
    setCart(newCart);
  };

  // useEffect(() => {
  //   const token = localStorage.getItem("token");
  //   if (token) {
  //     axios.defaults.headers.common["Authorization"] = `Token ${token}`;
  //   }
  // }, []);

  return (
    <Router>
      <Routes>
        <Route path="/" element={<LandingPage />} />
        <Route path="/login" element={<LogInPage />} />
        <Route path="/register" element={<RegistrationPage />} />
        <Route path="/forgot-password" element={<ForgotPassword />} />
        <Route path="/verify-email" element={<VerificationPage />} />
        <Route path="/personal-info" element={<PersonalInfo />} />
        <Route path="/project-info" element={<ProjectInfo />} />
        <Route path="/employment-info" element={<EmploymentInfo />} />
        <Route path="/education-info" element={<EducationInfo />} />
        <Route path="/scientific-project" element={<ScientificProject />} />
        <Route path="/future-plans" element={<FuturePlan />} />
        <Route path="/evidence" element={<Evidence />} />
        <Route path="/rfe" element={<RFE />} />
        <Route path="/dashboard" element={<Dashboard />} />
        <Route path="/proposed-endeavor" element={<ProposedEndeavor />} />
        <Route path="/recommender-config" element={<RecommenderConfig />} />
        <Route path="/recommender" element={<Recommender />} />
        <Route path="/petition-letter" element={<PetitionLetter />} />
        <Route path="/rfe-analysis" element={<RfeAnalysis />} />
        <Route path="/rfe-response" element={<RfeResponse />} />
        <Route path="/all-plans" element={<AllPlans />} />
        <Route
          path="/plans"
          element={<Plans handleAddToCart={handleAddToCart} />}
        />
        <Route
          path="/cart"
          element={<Cart cart={cart} removeFromCart={handleRemoveFromCart} />}
        />
      </Routes>
    </Router>
  );
}

export default App;

// import React from "react";

// function LandingPage() {
//   return (
//     <div className="landing">
//       <h1>Welcome to Our Application</h1>
//       <p>Your gateway to seamless interaction.</p>
//       <div>
//         <a href="/login" className="btn">
//           Login
//         </a>
//       </div>

//       <div>
//         <a href="/register" className="btn">
//           Register
//         </a>
//       </div>

//       <div>
//         <a href="/personal-info" className="btn">
//           Personal info
//         </a>
//       </div>

//       <div>
//         <a href="/project-info" className="btn">
//           Project info
//         </a>
//       </div>

//       <div>
//         <a href="/employment-info" className="btn">
//           Employment info
//         </a>
//       </div>

//       <div>
//         <a href="/education-info" className="btn">
//           Education info
//         </a>
//       </div>

//       <div>
//         <a href="/scientific-project" className="btn">
//           Scientific project
//         </a>
//       </div>

//       <div>
//         <a href="/future-plans" className="btn">
//           Future plans
//         </a>
//       </div>

//       <div>
//         <a href="/evidence" className="btn">
//           Evidence
//         </a>
//       </div>

//       <div>
//         <a href="/rfe" className="btn">
//           RFE
//         </a>
//       </div>
//     </div>
//   );
// }
// export default LandingPage;
// import React from "react";
