import React, { useEffect, useRef } from "react";
import Header from "../common/Header";
import Footer from "../common/Footer";
import PricingCard from "../common/PricingCard";
import styles from "./AllPlans.module.css";

const AllPlans = () => {
  return (
    <div className={styles.landingPage}>
      <Header />

      {/* Pricing Section */}
      <section className={styles.pricingSection}>
        <div className={styles.pricingHeader}>
          <h2 className={styles.pricingTitle}>
            Select <span className={styles.titleGray}>the</span> Service{" "}
            <span className={styles.titleGray}>Kit That</span> Fits{" "}
            <span className={styles.titleGray}>Your Needs</span>
          </h2>
          <p className={styles.pricingDescription}>
            Explore our range of{" "}
            <span className={styles.textGray}>
              service kits designed to meet the unique requirements of your NIW
            </span>{" "}
            application process.
          </p>
        </div>

        <div className={styles.pricingGrid}>
          <PricingCard
            title="Recommendation Letter Kit"
            features={[
              "Fast, simple evaluation on referee background",
              "AI-powered customized generation based on referee background",
              "Highlight your achievement and emphasize proposed endeavor",
            ]}
            price={149}
            originalPrice={249}
            period="/letter"
          />

          <PricingCard
            title="NIW Full Petition Kit"
            features={[
              "Include Recommendation Kit with 5 letters",
              "Petition Letter generation with numerous exhibition supports and government documents",
              "Instructions, I40 filing",
            ]}
            price={1049}
            originalPrice={1349}
            period="/kit"
            featured={true}
            dividerText="What you'll get"
          />

          <PricingCard
            title="RFE Kit"
            features={[
              "Instructions, I40filings",
              "Recommendation Letter x 5",
              "Petition Letter generation with numerous exhibition supports",
            ]}
            price={1489}
            originalPrice={1349}
            period="/kit"
            dividerText="What you'll get"
          />

          <PricingCard
            title="Elite Kit"
            features={["Placeholder", "Placeholder", "Placeholder"]}
            price={149}
            originalPrice={249}
            period="/kit"
            dividerText="What you'll get"
          />
        </div>
      </section>

      <Footer />
    </div>
  );
};

export default AllPlans;
