import React, { useState, useRef, useEffect } from "react";
import styles from "./Dropdown.module.css";

const CustomDropdown = ({
  value,
  onChange,
  options,
  placeholder,
  name,
  error,
  label,
  required = false,
  disabled = false,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, []);

  const handleSelect = (selectedValue) => {
    onChange({ target: { name, value: selectedValue } });
    setIsOpen(false);
  };

  return (
    <div className={styles.dropdownWrapper} ref={dropdownRef}>
      {label && (
        <label className={styles.label}>
          {label} {required && <span className={styles.required}>*</span>}
        </label>
      )}
      <div
        className={`${styles.dropdownTrigger} ${error ? styles.error : ""} ${
          disabled ? styles.disabled : ""
        }`}
        onClick={() => !disabled && setIsOpen(!isOpen)}
      >
        <span
          className={`${styles.selectedValue} ${
            !value ? styles.placeholder : ""
          }`}
        >
          {value || placeholder}
        </span>
        <svg
          className={`${styles.arrow} ${isOpen ? styles.open : ""}`}
          viewBox="0 0 24 24"
          fill="none"
          stroke="currentColor"
        >
          <path
            d="M6 9l6 6 6-6"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      </div>

      {isOpen && !disabled && (
        <div className={styles.optionsList}>
          {options.map((option) => (
            <div
              key={option}
              className={`${styles.option} ${
                option === value ? styles.selected : ""
              }`}
              onClick={() => handleSelect(option)}
            >
              {option}
            </div>
          ))}
        </div>
      )}
      {error && <span className={styles.errorMessage}>{error}</span>}
    </div>
  );
};

export default CustomDropdown;
