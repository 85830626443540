import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import styles from "./proposed_endeavor.module.css";
import Sidebar from "../common/Sidebar";
import Header2 from "../common/Header2";
import Button from "../common/Button";

function ProposedEndeavor() {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);

  const [showInput, setShowInput] = useState(false);
  const [submittedEndeavors, setSubmittedEndeavors] = useState([]);
  const [selectedEndeavors, setSelectedEndeavors] = useState([]);
  const [expandedEndeavors, setExpandedEndeavors] = useState({});
  const [endeavorForm, setEndeavorForm] = useState({
    title: "",
    qualifications: "",
    experience: "",
    merit: {
      intrinsicValue: "",
      impact: "",
      achievements: "",
    },
    nationalImportance: {
      scope: "",
      urgentNeed: "",
      beneficiaries: "",
      impact: "",
    },
  });

  const handleEditProfile = () => {
    navigate("/personal-info");
  };

  const handleAddEndeavor = () => {
    setShowInput(true);
  };

  const handleFormChange = (e, section, subsection = null) => {
    if (subsection) {
      setEndeavorForm((prev) => ({
        ...prev,
        [section]: {
          ...prev[section],
          [subsection]: e.target.value,
        },
      }));
    } else {
      setEndeavorForm((prev) => ({
        ...prev,
        [section]: e.target.value,
      }));
    }
  };

  const handleSubmitEndeavor = (e) => {
    e.preventDefault();
    setSubmittedEndeavors([...submittedEndeavors, { ...endeavorForm }]);

    setEndeavorForm({
      title: "",
      qualifications: "",
      experience: "",
      merit: { intrinsicValue: "", impact: "", achievements: "" },
      nationalImportance: {
        scope: "",
        urgentNeed: "",
        beneficiaries: "",
        impact: "",
      },
    });
    setShowInput(false);
  };

  const handleCheckboxChange = (index) => {
    setSelectedEndeavors((prev) => {
      if (prev.includes(index)) {
        return prev.filter((i) => i !== index);
      }
      return [...prev, index];
    });
  };
  const handleSave = (e) => handleFormSubmit(e, "save");
  const handleContinue = (e) => handleFormSubmit(e, "continue");
  const handleFormSubmit = async (e, action = "save") => {
    e.preventDefault();
    setIsLoading(true);
    try {
      if (action === "save") {
        // Save the form data
        console.log("Form data saved:", endeavorForm);
      } else if (action === "continue") {
        // Continue to the next step
        console.log("Form data submitted:", endeavorForm);
      }
    } catch (error) {
      console.error("Error submitting form:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const toggleEndeavor = (index, e) => {
    e.stopPropagation();
    setExpandedEndeavors((prev) => ({
      ...prev,
      [index]: !prev[index],
    }));
  };

  return (
    <div className={styles.container}>
      <Header2 />
      <div className={styles.pageLayout}>
        <Sidebar />
        <div className={styles.mainContent}>
          <div className={styles.wrapper}>
            <h1 className={styles.title}>Proposed Endeavor</h1>

            <div className={styles.aiGeneratorCard}>
              <div className={styles.generatorHeader}>
                <div className={styles.headerLeft}>
                  <span className={styles.aiIcon}>✨</span>
                  <span>Endeavor AI Generator</span>
                </div>
                <button className={styles.collapseButton}>
                  <span>▼</span>
                </button>
              </div>

              <div className={styles.generatorContent}>
                <p>Generate 10 endeavors based on your profile</p>
                <div className={styles.actionButtons}>
                  <button className={styles.generateButton}>
                    Generate Now
                  </button>
                  {/* <button
                    className={styles.editProfileButton}
                    onClick={handleEditProfile}
                  >
                    Edit My Profile
                  </button> */}
                </div>
              </div>
            </div>

            <div className={styles.endeavorsContainer}>
              {submittedEndeavors.length > 0 && (
                <div className={styles.submittedEndeavorsContainer}>
                  <h2 className={styles.submittedEndeavorsTitle}>
                    Your Proposed Endeavors
                  </h2>
                  {submittedEndeavors.map((endeavor, index) => (
                    <div key={index} className={styles.submittedEndeavor}>
                      <div className={styles.endeavorHeader}>
                        <input
                          type="checkbox"
                          checked={selectedEndeavors.includes(index)}
                          onChange={() => handleCheckboxChange(index)}
                          className={styles.endeavorCheckbox}
                        />
                        <h2 className={styles.endeavorTitle}>
                          {endeavor.title || `Endeavor ${index + 1}`}
                        </h2>
                        <button
                          className={`${styles.dropdownButton} ${
                            expandedEndeavors[index] ? styles.expanded : ""
                          }`}
                          onClick={(e) => toggleEndeavor(index, e)}
                        >
                          ▼
                        </button>
                      </div>

                      {expandedEndeavors[index] && (
                        <div className={styles.endeavorDetails}>
                          <div>
                            <h3>Qualifications</h3>
                            <p>{endeavor.qualifications}</p>
                          </div>

                          <div>
                            <h3>Experience</h3>
                            <p>{endeavor.experience}</p>
                          </div>

                          <div>
                            <h3>Substantial Merit</h3>
                            <div className={styles.meritSection}>
                              <p>
                                <strong>Intrinsic Value:</strong>{" "}
                                {endeavor.merit.intrinsicValue}
                              </p>
                              <p>
                                <strong>Impact:</strong> {endeavor.merit.impact}
                              </p>
                              <p>
                                <strong>Achievements:</strong>{" "}
                                {endeavor.merit.achievements}
                              </p>
                            </div>
                          </div>

                          <div>
                            <h3>National Importance</h3>
                            <div className={styles.nationalImportanceSection}>
                              <p>
                                <strong>Scope:</strong>{" "}
                                {endeavor.nationalImportance.scope}
                              </p>
                              <p>
                                <strong>Urgent Need:</strong>{" "}
                                {endeavor.nationalImportance.urgentNeed}
                              </p>
                              <p>
                                <strong>Beneficiaries:</strong>{" "}
                                {endeavor.nationalImportance.beneficiaries}
                              </p>
                              <p>
                                <strong>Impact:</strong>{" "}
                                {endeavor.nationalImportance.impact}
                              </p>
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  ))}
                </div>
              )}

              {showInput && (
                <form
                  onSubmit={handleSubmitEndeavor}
                  className={styles.endeavorForm}
                >
                  <div className={styles.formSection}>
                    <input
                      type="text"
                      value={endeavorForm.title}
                      onChange={(e) => handleFormChange(e, "title")}
                      placeholder="Endeavor Title"
                      className={styles.textInput}
                      required
                    />
                  </div>

                  <div className={styles.formSection}>
                    <h3>Applicant Qualifications</h3>
                    <textarea
                      value={endeavorForm.qualifications}
                      onChange={(e) => handleFormChange(e, "qualifications")}
                      placeholder="List your relevant qualifications..."
                      className={styles.textarea}
                      required
                    />
                  </div>

                  <div className={styles.formSection}>
                    <h3>Related Experience</h3>
                    <textarea
                      value={endeavorForm.experience}
                      onChange={(e) => handleFormChange(e, "experience")}
                      placeholder="Describe your related experience..."
                      className={styles.textarea}
                      required
                    />
                  </div>

                  <div className={styles.formSection}>
                    <h3>Substantial Merit</h3>
                    <div className={styles.subSection}>
                      <textarea
                        value={endeavorForm.merit.intrinsicValue}
                        onChange={(e) =>
                          handleFormChange(e, "merit", "intrinsicValue")
                        }
                        placeholder="Intrinsic Value..."
                        className={styles.textarea}
                        required
                      />
                      <textarea
                        value={endeavorForm.merit.impact}
                        onChange={(e) => handleFormChange(e, "merit", "impact")}
                        placeholder="Impact..."
                        className={styles.textarea}
                        required
                      />
                      <textarea
                        value={endeavorForm.merit.achievements}
                        onChange={(e) =>
                          handleFormChange(e, "merit", "achievements")
                        }
                        placeholder="Evidence of Achievements..."
                        className={styles.textarea}
                        required
                      />
                    </div>
                  </div>

                  <div className={styles.formSection}>
                    <h3>National Importance</h3>
                    <div className={styles.subSection}>
                      <textarea
                        value={endeavorForm.nationalImportance.scope}
                        onChange={(e) =>
                          handleFormChange(e, "nationalImportance", "scope")
                        }
                        placeholder="Scope..."
                        className={styles.textarea}
                        required
                      />
                      <textarea
                        value={endeavorForm.nationalImportance.urgentNeed}
                        onChange={(e) =>
                          handleFormChange(
                            e,
                            "nationalImportance",
                            "urgentNeed"
                          )
                        }
                        placeholder="Urgent Need..."
                        className={styles.textarea}
                        required
                      />
                      <textarea
                        value={endeavorForm.nationalImportance.beneficiaries}
                        onChange={(e) =>
                          handleFormChange(
                            e,
                            "nationalImportance",
                            "beneficiaries"
                          )
                        }
                        placeholder="Beneficiaries..."
                        className={styles.textarea}
                        required
                      />
                      <textarea
                        value={endeavorForm.nationalImportance.impact}
                        onChange={(e) =>
                          handleFormChange(e, "nationalImportance", "impact")
                        }
                        placeholder="Impact..."
                        className={styles.textarea}
                        required
                      />
                    </div>
                  </div>

                  <div className={styles.formActions}>
                    <button type="submit" className={styles.submitButton}>
                      Submit Endeavor
                    </button>
                    <button
                      type="button"
                      className={styles.cancelButton}
                      onClick={() => setShowInput(false)}
                    >
                      Cancel
                    </button>
                  </div>
                </form>
              )}

              <div className={styles.addEndeavorHeader}>
                <button
                  className={styles.addEndeavorButton}
                  onClick={handleAddEndeavor}
                >
                  + Add New Endeavor
                </button>
              </div>
            </div>
            <div className={styles.buttoncontainer}>
              <Button variant="secondary" onClick={handleSave}>
                Save
              </Button>
              <Button variant="primary" type="submit" disabled={isLoading}>
                {isLoading ? "Processing..." : "Continue"}
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ProposedEndeavor;
