//Todo: after login, prevent users to go back to login page
import { redirect, useNavigate } from "react-router-dom";
import React, { useState } from "react";
import logo from "../assets/images/logo.svg";
import hi from "../assets/images/login-image-hi.png";
import google from "../assets/images/login-social-icon-google-logo.svg";
import styles from "./Login.module.css";
import axios from "axios";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import { Link } from "react-router-dom";
import config from "../config.js";
import { GoogleLogin } from "@react-oauth/google";
import { authApi } from "../services/api.js";
import { protectedApi } from "../services/api.js";

function LogInPage() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [rememberMe, setRememberMe] = useState(false);
  const [formError, setFormError] = useState("");

  const navigate = useNavigate();

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const handleGoogleSuccess = async (credentialResponse) => {
    const { credential } = credentialResponse;

    try {
      const response = await authApi.post("/api/auth/google/", { credential });
      navigate("/personal-info");
    } catch (error) {
      console.error("Google Login Failed:", error);
      alert("Failed to log in with Google. Please try again.");
      setFormError("Failed to log in with Google. Please try again.");
    }
  };

  const handleGoogleFailure = (error) => {
    console.error("Google Login Failed:", error);
    alert("Failed to log in with Google. Please try again.");
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      console.log("Attempting login...");
      const loginResponse = await authApi.post("/api/auth/login/", {
        email,
        password,
      });

      if (loginResponse.data.user) {
        console.log("Login successful:", loginResponse.data);
        localStorage.setItem("user", JSON.stringify(loginResponse.data.user));

        navigate("/personal-info");
      }
    } catch (error) {
      console.error("Login error:", {
        message: error.message,
        response: error.response?.data,
        status: error.response?.status,
      });
      alert(error.response?.data?.detail || "Login failed");
      setFormError("Invalid credentials or server error");
    }
  };

  return (
    <div className={styles.login}>
      <form onSubmit={handleSubmit}>
        <div className={styles.input}>
          <div className={styles.title}>Email address</div>
          <input
            type="email"
            placeholder="Your email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
            className={styles.inputField}
          />
        </div>
        <div className={styles.input1}>
          <div className={styles.title}>Password</div>
          <div className={styles.textContainer}>
            <input
              type={showPassword ? "text" : "password"}
              placeholder="Password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
              className={styles.inputField1}
            />
            <button
              type="button"
              onClick={togglePasswordVisibility}
              className={styles.iconButton}
            >
              {showPassword ? <FaEyeSlash /> : <FaEye />}
            </button>
          </div>
        </div>
        <b className={styles.hiWelcome}>Hi, Welcome!</b>
        <div className={styles.rememberMe}>
          <input
            type="checkbox"
            checked={rememberMe}
            onChange={(e) => setRememberMe(e.target.checked)}
          />
          Remember me
        </div>
        <img className={styles.image297Icon} alt="" src={hi} />

        <div className={styles.forgotPassword}>
          <Link to="/forgot-password" className={styles.forgotPasswordLink}>
            Forgot password?
          </Link>
        </div>
        <div className={styles.dontHaveAnContainer}>
          <span className={styles.dontHaveAnContainer1}>
            <span>Don’t have an account?</span>
            <span className={styles.span}>{` `}</span>
            <span className={styles.span}>
              <Link to="/register" className={styles.signUp1}>
                Sign up
              </Link>
            </span>
          </span>
        </div>
        <div className={styles.orWithParent}>
          <div className={styles.orWith}>Or </div>
          <div className={styles.groupChild} />
          <div className={styles.groupItem} />
        </div>
        <button type="submit" className={styles.buttonPrimary}>
          Log in
        </button>
      </form>

      <div className={styles.buttonWithCenteredIcon1}>
        <GoogleLogin
          onSuccess={handleGoogleSuccess}
          onError={handleGoogleFailure}
        />
      </div>
      <Link to="/">
        <img className={styles.navbarIcon} alt="logo" src={logo} />
      </Link>
    </div>
  );
}

export default LogInPage;
