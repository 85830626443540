import React from "react";
import { useNavigate } from "react-router-dom";
import styles from "./ProgressBar.module.css";

function Stepper({ currentStep, totalSteps }) {
  const navigate = useNavigate();

  // Define step paths
  const stepPaths = {
    1: "/personal-info",
    2: "/education-info",
    3: "/scientific-project",
    4: "/employment-info",
    5: "/project-info",
    6: "/evidence",
    7: "/future-plans",
  };

  // Handle click on progress bar sections
  const handleStepClick = (stepNumber) => {
    // Only allow navigation to completed steps or the next immediate step
    if (stepNumber <= currentStep || stepNumber === currentStep + 1) {
      navigate(stepPaths[stepNumber]);
    }
  };

  // Calculate sections for the progress bar
  const renderProgressSections = () => {
    return Array.from({ length: totalSteps }, (_, index) => {
      const stepNumber = index + 1;
      const isCompleted = stepNumber <= currentStep;
      const isClickable =
        stepNumber <= currentStep || stepNumber === currentStep + 1;

      return (
        <div
          key={stepNumber}
          className={`${styles.progressSection} ${
            isCompleted ? styles.completed : ""
          } ${isClickable ? styles.clickable : ""}`}
          style={{ width: `${100 / totalSteps}%` }}
          onClick={() => handleStepClick(stepNumber)}
          title={`Step ${stepNumber}`}
        />
      );
    });
  };

  return (
    <div className={styles.stepper}>
      <div>
        <span className={styles.step}>Step {currentStep}</span>
        <span className={styles.totalSteps}> of {totalSteps}</span>
      </div>
      <div className={styles.barcontainer}>
        <div className={styles.bg} />
        <div className={styles.progressSections}>
          {renderProgressSections()}
        </div>
        <div
          className={styles.bar}
          style={{ width: `${(currentStep / totalSteps) * 100}%` }}
        />
      </div>
    </div>
  );
}

export default Stepper;
