import React from "react";
import styles from "./Input.module.css";

const Input = ({
  type = "text",
  value,
  onChange,
  name,
  placeholder,
  error,
  className,
  width = "100%",
  style,
  disabled = false,
  required = false,
}) => {
  return (
    <div className={styles.inputWrapper} style={{ width, ...style }}>
      <input
        type={type}
        value={value}
        onChange={onChange}
        name={name}
        placeholder={required ? `${placeholder} *` : placeholder}
        className={`${styles.inputField} ${error ? styles.errorInput : ""} ${
          className || ""
        }`}
        disabled={disabled}
        required={required}
      />
      {error && <span className={styles.errorMessage}>{error}</span>}
    </div>
  );
};

export default Input;
