import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import Sidebar from "../common/Sidebar.js";
import Header2 from "../common/Header2.js";
import styles from "./plans.module.css";
import Button from "../common/Button.js";
import { protectedApi } from "../../services/api.js";
import { FaShoppingCart } from "react-icons/fa";

function Plans({ handleAddToCart }) {
  const navigate = useNavigate();
  const [quantities, setQuantities] = useState({
    "Full Package": 1,
    "Full Package Plus RFE": 1,
    "RFE Package": 1,
    "Recommendation Letters": 1,
    "RFE Notice Analysis": 1,
  });

  const handleQuantityChange = (planName, change) => {
    setQuantities((prev) => ({
      ...prev,
      [planName]: Math.max(1, prev[planName] + change),
    }));
  };

  const handlePlanSelection = (plan) => {
    handleAddToCart({
      ...plan,
      quantity: quantities[plan.name],
      price: plan.price * quantities[plan.name],
    });
    navigate("/cart");
  };

  return (
    <div className={styles.container}>
      <Header2 />
      <div className={styles.pageLayout}>
        <Sidebar />
        <div className={styles.mainContent}>
          <div className={styles.headerSection}>
            <h2 className={styles.title}>Plans</h2>
            <Button
              className={styles.cartButton}
              onClick={() => navigate("/cart")}
            >
              <FaShoppingCart /> View Cart
            </Button>
          </div>

          <div className={styles.introSection}>
            <p className={styles.subtitle}>
              If it is your first time filing, we recommend you to choose from
              the below Packages.
            </p>
          </div>
          <div className={styles.plansContainer}>
            {/* Full Package */}
            <div className={styles.planCard}>
              <h3>Full Package</h3>
              <p className={styles.price}>$1049</p>
              <p className={styles.originalPrice}>$1349</p>
              <div className={styles.features}>
                <p>10 Propsed Endeavors</p>
                <p>5 Recommendation Letters</p>
                <p>1 Petition Letter</p>
              </div>
              <div className={styles.buttonContainer}>
                <Button
                  className={styles.planButton}
                  onClick={() =>
                    handlePlanSelection({
                      name: "Full Package",
                      price: 1049,
                    })
                  }
                >
                  Add to Cart
                </Button>
              </div>
            </div>

            {/* Full Package Plus RFE */}
            <div className={styles.planCard}>
              <h3>Full Package Plus RFE</h3>
              <p className={styles.price}>$1899</p>
              <p className={styles.originalPrice}>$2548</p>
              <div className={styles.features}>
                <p>10 Propsed Endeavor</p>
                <p>5 Recommendation Letters</p>
                <p>1 Petition Letter</p>
                <p>1 RFE Analysis</p>
                <p>1 RFE Notice Response</p>
              </div>
              <div className={styles.buttonContainer}>
                <Button
                  className={styles.planButton}
                  onClick={() =>
                    handlePlanSelection({
                      name: "Full Package Plus RFE",
                      price: 1899,
                    })
                  }
                >
                  Add to Cart
                </Button>
              </div>
            </div>

            {/* Elite Package */}
            <div className={styles.planCard}>
              <h3>Elite Package</h3>
              <p className={styles.price}>Custom Pricing</p>
              <div className={styles.features}>
                <p>Custom Features</p>
              </div>
              <div className={styles.buttonContainer}>
                <Button
                  className={styles.planButton}
                  onClick={() => navigate("/contact")}
                >
                  Contact Us
                </Button>
              </div>
            </div>
          </div>

          <div className={styles.rfeSection}>
            <p className={styles.subtitle}>
              If you already have a pending RFE, you can choose from the below
              Packages.
            </p>
          </div>

          <div className={styles.plansContainer}>
            {/* RFE Package */}
            <div className={styles.planCard}>
              <h3>RFE Package</h3>
              <p className={styles.price}>$1499</p>
              <p className={styles.originalPrice}>$1899</p>
              <div className={styles.features}>
                <p>No Proposed Endeavor</p>
                <p>1 RFE Analysis</p>
                <p>5 Recommendation Letters</p>
                <p>1 Response Letter </p>
              </div>
              <div className={styles.buttonContainer}>
                <Button
                  className={styles.planButton}
                  onClick={() =>
                    handlePlanSelection({
                      name: "RFE Package",
                      price: 1499,
                    })
                  }
                >
                  Add to Cart
                </Button>
              </div>
            </div>
            <div className={styles.planCard}>
              <h3>Elite Package</h3>
              <p className={styles.price}>Custom Pricing</p>
              <div className={styles.features}>
                <p>Custom Features</p>
              </div>
              <div className={styles.buttonContainer}>
                <Button
                  className={styles.planButton}
                  onClick={() => navigate("/contact")}
                >
                  Contact Us
                </Button>
              </div>
            </div>
          </div>

          {/* Add-Ons Section */}
          <h2 className={styles.sectionTitle}>Add-Ons</h2>
          <div className={styles.addOnsContainer}>
            <div className={styles.addOnCard}>
              <h3>Recommendation Letters</h3>
              <p className={styles.price}>$149</p>
              <div className={styles.buttonContainer}>
                <div className={styles.quantitySelector}>
                  <button
                    className={styles.quantityButton}
                    onClick={() =>
                      handleQuantityChange("Recommendation Letters", -1)
                    }
                  >
                    -
                  </button>
                  <span className={styles.quantityDisplay}>
                    {quantities["Recommendation Letters"]}
                  </span>
                  <button
                    className={styles.quantityButton}
                    onClick={() =>
                      handleQuantityChange("Recommendation Letters", 1)
                    }
                  >
                    +
                  </button>
                </div>
                <Button
                  className={styles.planButton}
                  onClick={() =>
                    handlePlanSelection({
                      name: "Recommendation Letters",
                      price: 149,
                    })
                  }
                >
                  Add to Cart
                </Button>
              </div>
            </div>

            <div className={styles.addOnCard}>
              <h3>RFE Notice Analysis</h3>
              <p className={styles.price}>$599</p>
              <div className={styles.buttonContainer}>
                <div className={styles.quantitySelector}>
                  <button
                    className={styles.quantityButton}
                    onClick={() =>
                      handleQuantityChange("RFE Notice Analysis", -1)
                    }
                  >
                    -
                  </button>
                  <span className={styles.quantityDisplay}>
                    {quantities["RFE Notice Analysis"]}
                  </span>
                  <button
                    className={styles.quantityButton}
                    onClick={() =>
                      handleQuantityChange("RFE Notice Analysis", 1)
                    }
                  >
                    +
                  </button>
                </div>
                <Button
                  className={styles.planButton}
                  onClick={() =>
                    handlePlanSelection({
                      name: "RFE Notice Analysis",
                      price: 599,
                    })
                  }
                >
                  Add to Cart
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Plans;
