// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.FileUpload_fileUploadContainer__snMX4 {
  margin-top: 8px;
}

.FileUpload_fileInput__8G0jh {
  display: none;
}

.FileUpload_fileInputLabel__KStHu {
  display: block;
  width: 35%;
  height: 40px;
  padding: 8px 16px;
  border: 1px solid #E6E6E6;
  border-radius: 4px;
  font-size: 14px;
  line-height: 20px;
  background-color: #fff;
  cursor: pointer;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-family: 'Open Sans', sans-serif;
  color: #1e70bb;
}

.FileUpload_uploadedFiles__50AQ9 {
  margin-top: 10px;
}

.FileUpload_fileItem__5VHKn {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #f5f5f5;
  padding: 8px 12px;
  margin: 5px 0;
  border-radius: 4px;
}

.FileUpload_removeFile__Fs8cM {
  background: none;
  border: none;
  color: #ff4444;
  cursor: pointer;
  font-size: 18px;
  padding: 0 5px;
}

.FileUpload_removeFile__Fs8cM:hover {
  color: #cc0000;
}
`, "",{"version":3,"sources":["webpack://./src/components/common/FileUpload.module.css"],"names":[],"mappings":"AAAA;EACE,eAAe;AACjB;;AAEA;EACE,aAAa;AACf;;AAEA;EACE,cAAc;EACd,UAAU;EACV,YAAY;EACZ,iBAAiB;EACjB,yBAAyB;EACzB,kBAAkB;EAClB,eAAe;EACf,iBAAiB;EACjB,sBAAsB;EACtB,eAAe;EACf,gBAAgB;EAChB,uBAAuB;EACvB,mBAAmB;EACnB,oCAAoC;EACpC,cAAc;AAChB;;AAEA;EACE,gBAAgB;AAClB;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,8BAA8B;EAC9B,yBAAyB;EACzB,iBAAiB;EACjB,aAAa;EACb,kBAAkB;AACpB;;AAEA;EACE,gBAAgB;EAChB,YAAY;EACZ,cAAc;EACd,eAAe;EACf,eAAe;EACf,cAAc;AAChB;;AAEA;EACE,cAAc;AAChB","sourcesContent":[".fileUploadContainer {\n  margin-top: 8px;\n}\n\n.fileInput {\n  display: none;\n}\n\n.fileInputLabel {\n  display: block;\n  width: 35%;\n  height: 40px;\n  padding: 8px 16px;\n  border: 1px solid #E6E6E6;\n  border-radius: 4px;\n  font-size: 14px;\n  line-height: 20px;\n  background-color: #fff;\n  cursor: pointer;\n  overflow: hidden;\n  text-overflow: ellipsis;\n  white-space: nowrap;\n  font-family: 'Open Sans', sans-serif;\n  color: #1e70bb;\n}\n\n.uploadedFiles {\n  margin-top: 10px;\n}\n\n.fileItem {\n  display: flex;\n  align-items: center;\n  justify-content: space-between;\n  background-color: #f5f5f5;\n  padding: 8px 12px;\n  margin: 5px 0;\n  border-radius: 4px;\n}\n\n.removeFile {\n  background: none;\n  border: none;\n  color: #ff4444;\n  cursor: pointer;\n  font-size: 18px;\n  padding: 0 5px;\n}\n\n.removeFile:hover {\n  color: #cc0000;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"fileUploadContainer": `FileUpload_fileUploadContainer__snMX4`,
	"fileInput": `FileUpload_fileInput__8G0jh`,
	"fileInputLabel": `FileUpload_fileInputLabel__KStHu`,
	"uploadedFiles": `FileUpload_uploadedFiles__50AQ9`,
	"fileItem": `FileUpload_fileItem__5VHKn`,
	"removeFile": `FileUpload_removeFile__Fs8cM`
};
export default ___CSS_LOADER_EXPORT___;
