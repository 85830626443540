import React, { useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import styles from "./Sidebar.module.css";

import { ReactComponent as DashboardIcon } from "../../assets/images/dashboard.svg";
import { ReactComponent as ApplicationIcon } from "../../assets/images/application.svg";
import { ReactComponent as ProfileIcon } from "../../assets/images/profile.svg";
import { ReactComponent as ProposedEndeavorsIcon } from "../../assets/images/proposed_endeavor.svg";
import { ReactComponent as RecommendationsIcon } from "../../assets/images/application.svg";
import { ReactComponent as PetitionLetterIcon } from "../../assets/images/application.svg";

function Sidebar() {
  const navigate = useNavigate();
  const location = useLocation();
  const [isDropdownOpen, setDropdownOpen] = useState(false);

  const toggleDropdown = () => {
    setDropdownOpen(!isDropdownOpen);
  };

  const isActive = (path) => {
    return location.pathname === path;
  };

  return (
    <div className={styles.menu}>
      {/* <div className={styles.sidebarHeader}>
        <img src={logo} alt="TurboBe Logo" className={styles.logo} />
        <h1 className={styles.sidebarTitle}>TurboBe</h1>
      </div> */}

      <div className={styles.menuItems}>
        <div
          className={`${styles.listManu} ${
            isActive("/dashboard") ? styles.active : ""
          }`}
          onClick={() => navigate("/dashboard")}
        >
          <div className={styles.content}>
            <DashboardIcon className={styles.icons} />
            <div className={styles.text2}>Dashboard</div>
          </div>
        </div>

        <div>
          <div
            className={`${styles.listManu} ${
              isActive("/application") ? styles.active : ""
            }`}
            onClick={toggleDropdown}
          >
            <div className={styles.content}>
              <ApplicationIcon className={styles.icons} />
              <div className={styles.text2}>Application</div>
            </div>
          </div>

          {isDropdownOpen && (
            <div className={styles.dropdown}>
              <div
                className={`${styles.dropdownItem} ${
                  isActive("/application/profile") ? styles.active : ""
                }`}
                onClick={() => navigate("/application/profile")}
              >
                <ProfileIcon className={styles.dropdownIcon} />
                <span>Profile</span>
              </div>
              <div
                className={`${styles.dropdownItem} ${
                  isActive("/application/proposed-endeavors")
                    ? styles.active
                    : ""
                }`}
                onClick={() => navigate("/application/proposed-endeavors")}
              >
                <ProposedEndeavorsIcon className={styles.dropdownIcon} />
                <span>Proposed Endeavors</span>
              </div>
              <div
                className={`${styles.dropdownItem} ${
                  isActive("/application/recommendations") ? styles.active : ""
                }`}
                onClick={() => navigate("/application/recommendations")}
              >
                <RecommendationsIcon className={styles.dropdownIcon} />
                <span>Recommendations</span>
              </div>
              <div
                className={`${styles.dropdownItem} ${
                  isActive("/application/petition-letter") ? styles.active : ""
                }`}
                onClick={() => navigate("/application/petition-letter")}
              >
                <PetitionLetterIcon className={styles.dropdownIcon} />
                <span>Petition Letter</span>
              </div>
            </div>
          )}
        </div>
        <div
          className={`${styles.listManu} ${
            isActive("/plans") ? styles.active : ""
          }`}
          onClick={() => navigate("/plans")}
        >
          <div className={styles.content}>
            <ApplicationIcon className={styles.icons} />
            <div className={styles.text2}>Plans & Billing</div>
          </div>
        </div>
      </div>

      <div className={styles.sidebarFooter}>
        <div className={styles.userInfo}>
          {/* <div className={styles.userEmail}>user@example.com</div> */}
          <button
            className={styles.logoutButton}
            onClick={() => navigate("/login")}
          >
            Log Out
          </button>
        </div>
      </div>
    </div>
  );
}

export default Sidebar;
