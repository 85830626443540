import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import config from "../config.js";
import styles from "./Register.module.css";
import logo from "../assets/images/logo.svg";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import VerificationPopup from "./VerificationPage.js";

function CreateAccount() {
  const [values, setValues] = useState({
    username: "",
    email: "",
    password: "",
    acceptedTerms: false,
  });
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);
  const [emailError, setEmailError] = useState("");
  const [usernameError, setUsernameError] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [formError, setFormError] = useState("");
  const [showVerificationPopup, setShowVerificationPopup] = useState(false);
  const [loading, setLoading] = useState(false);
  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setValues((prevValues) => ({
      ...prevValues,
      [name]: type === "checkbox" ? checked : value,
    }));
  };

  const showPopup = (message, type = "success") => {
    const popup = document.createElement("div");
    popup.innerText = message;
    popup.className = `popup-message ${type}`;
    popup.style.position = "fixed";
    popup.style.top = "20%";
    popup.style.left = "50%";
    popup.style.transform = "translate(-50%, -50%)";
    popup.style.padding = "20px";
    popup.style.backgroundColor = type === "success" ? "#4CAF50" : "#F44336";
    popup.style.color = "#fff";
    popup.style.borderRadius = "12px";
    popup.style.boxShadow = "0 8px 16px rgba(0, 0, 0, 0.2)";
    popup.style.fontSize = "16px";
    popup.style.textAlign = "center";
    popup.style.zIndex = "1000";
    popup.style.opacity = "1";
    popup.style.transition = "opacity 0.3s ease, transform 0.3s ease";

    document.body.appendChild(popup);

    setTimeout(() => {
      popup.style.opacity = "0";
      popup.style.transform = "translate(-50%, -40%)";
      setTimeout(() => {
        document.body.removeChild(popup);
      }, 300);
    }, 3000);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    let valid = true;
    setUsernameError("");
    setEmailError("");
    setPasswordError("");

    if (!values.username || !values.email || !values.password) {
      alert("All fields are required", "error");
      valid = false;
    } else if (!/^[a-zA-Z0-9]+$/.test(values.username)) {
      setUsernameError("Please enter a valid username, no special characters");
      valid = false;
    } else if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(values.email)) {
      setEmailError("Please enter a valid email address");
      valid = false;
    } else if (!/^(?=.*[A-Z])(?=.*[a-z])(?=.*\d).{6,}$/.test(values.password)) {
      setPasswordError(
        "Password must be at least six characters long, contain at least one uppercase letter and one lowercase letter."
      );
      valid = false;
    } else if (!values.acceptedTerms) {
      alert("Please accept the terms and privacy policy", "error");
      valid = false;
    }

    if (valid) {
      try {
        console.log(values);
        await axios.post(`${config.apiBaseUrl}/api/auth/register/`, values);
        alert("Account created!", "success");
        setTimeout(() => {
          navigate("/verify-email");
        }, 2000);
      } catch (error) {
        if (error.response) {
          const errorData = error.response.data;
          if (errorData.email) {
            setEmailError(`${errorData.email}`);
            // alert(`Email error: ${errorData.email}`, "error");
          } else if (errorData.username) {
            setUsernameError(`${errorData.username}`);
            // alert(`Username error: ${errorData.username}`, "error");
          } else if (errorData.password) {
            setPasswordError(`${errorData.password}`);
            // alert(`Password error: ${errorData.password}`, "error");
          } else {
            let errorMessage = "An unexpected error occurred";

            if (error.code === "ERR_NETWORK") {
              errorMessage =
                "Unable to connect to the server. Please try again later.";
            } else if (error.response?.status === 503) {
              errorMessage =
                "Service is temporarily unavailable. Please try again in a few minutes.";
            }

            alert(errorMessage, "error");
            console.error("Registration error:", error);
          }
        }
      } finally {
        setLoading(false);
      }
    } else {
      setLoading(false);
    }
  };

  return (
    <div className={styles.registration}>
      <a href="/">
        <img className={styles.navbarIcon} alt="logo" src={logo} />
      </a>
      <form onSubmit={handleSubmit}>
        <h2 className={styles.createAccount}>Create Account</h2>
        <div className={styles.input}>
          <label className={styles.title}>Username</label>
          <input
            type="text"
            name="username"
            value={values.username}
            onChange={handleChange}
            placeholder="Your username"
            className={styles.inputField}
          />
          {usernameError && (
            <div className={styles.errorText}>{usernameError}</div>
          )}
        </div>
        <div className={styles.input1}>
          <label className={styles.title}>Email</label>
          <input
            type="email"
            name="email"
            value={values.email}
            onChange={handleChange}
            placeholder="Your email"
            className={styles.inputField}
          />
          {emailError && <div className={styles.errorText}>{emailError}</div>}
        </div>
        <div className={styles.input2}>
          <label className={styles.title}>Password</label>
          <div className={styles.textContainer}>
            <input
              type={showPassword ? "text" : "password"}
              name="password"
              value={values.password}
              onChange={handleChange}
              placeholder="Your password"
              className={styles.inputField}
            />
            <button
              type="button"
              className={styles.iconInsideInput}
              onClick={() => setShowPassword(!showPassword)}
            >
              {showPassword ? <FaEyeSlash /> : <FaEye />}
            </button>
          </div>
          {passwordError && (
            <div className={styles.errorText}>{passwordError}</div>
          )}
        </div>
        <div className={styles.checkboxOn}>
          <input
            type="checkbox"
            name="acceptedTerms"
            checked={values.acceptedTerms}
            onChange={handleChange}
            className={styles.checkbox}
          />
          <span className={styles.iAcceptThe}>
            I accept the terms and privacy policy
          </span>
        </div>
        <button type="submit" className={styles.buttonPrimary}>
          {loading ? "Signing Up..." : "Sign Up"}
        </button>
        <div>
          {formError && <div className={styles.errorText}>{formError}</div>}
        </div>
      </form>
      <div className={styles.alreadyHaveAnContainer}>
        Already have an account?{" "}
        <a href="/login" className={`${styles.span} ${styles.logIn1}`}>
          Log in
        </a>
      </div>
      {showVerificationPopup && (
        <VerificationPopup onClose={() => setShowVerificationPopup(false)} />
      )}
    </div>
  );
}

export default CreateAccount;
