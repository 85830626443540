import React from "react";
import { Link } from "react-router-dom";
import styles from "./Header.module.css";
import logo from "../../assets/images/logo.svg";

const Header = () => {
  return (
    <header className={styles.header}>
      <div className={styles.header__logo}>
        <img src={logo} alt="Logo" className={styles.logo} />
        <span className={styles.logoText}></span>
      </div>
      <div className={styles.header__buttons}>
        <Link to="/login">
          <button className={styles.header__login}>Log In</button>
        </Link>
        <Link to="/register">
          <button className={styles.header__signup}>Sign up</button>
        </Link>
      </div>
    </header>
  );
};

export default Header;
