import React, { useState, useRef, useEffect } from "react";
import styles from "./SelectDropdown.module.css";

const Select = ({
  value,
  onChange,
  options,
  placeholder,
  name,
  error,
  width = "100%",
  required = false,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const selectRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (selectRef.current && !selectRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, []);

  const handleSelect = (option) => {
    onChange({ target: { name, value: option.value } });
    setIsOpen(false);
  };

  const selectedOption = options.find((opt) => opt.value === value);

  return (
    <div className={styles.selectWrapper} style={{ width }}>
      <div
        className={`${styles.selectTrigger} ${error ? styles.errorInput : ""}`}
        onClick={() => setIsOpen(!isOpen)}
        ref={selectRef}
      >
        <span>
          {selectedOption?.label ||
            (required ? `${placeholder} *` : placeholder)}
        </span>
        <span className={styles.arrow}>▼</span>
      </div>
      {isOpen && (
        <div className={styles.optionsList}>
          {options.map((option) => (
            <div
              key={option.value}
              className={styles.option}
              onClick={() => handleSelect(option)}
            >
              {option.label}
            </div>
          ))}
        </div>
      )}
      {error && <span className={styles.errorMessage}>{error}</span>}
    </div>
  );
};

export default Select;
