import React, { useState } from "react";
import styles from "./FileUpload.module.css";

const FileUpload = ({ onFileSelect, error, accept }) => {
  const [fileName, setFileName] = useState("");

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setFileName(file.name);
      onFileSelect(file);
    }
  };

  const handleRemoveFile = () => {
    setFileName("");
    onFileSelect(null);
  };

  return (
    <div className={styles.fileUploadContainer}>
      <input
        type="file"
        id="fileInput"
        className={styles.fileInput}
        onChange={handleFileChange}
        accept={accept}
      />
      <label htmlFor="fileInput" className={styles.fileInputLabel}>
        Choose File
      </label>
      {fileName && (
        <div className={styles.fileName}>
          <span>{fileName}</span>
          <button
            className={styles.removeFile}
            onClick={handleRemoveFile}
            type="button"
          >
            ×
          </button>
        </div>
      )}
      {error && <span className={styles.errorMessage}>{error}</span>}
    </div>
  );
};

export default FileUpload;
