import React from "react";
import styles from "./VerificationPage.module.css"; // Add styles to make it visually appealing

function VerificationPage() {
  return (
    <div className={styles.verificationContainer}>
      <h1 className={styles.heading}>Check Your Email</h1>
      <p className={styles.message}>
        Thank you for registering! A verification email has been sent to your
        provided email address. Please check your inbox (and spam folder) and
        click the verification link to activate your account.
      </p>
      <p className={styles.note}>
        If you did not receive the email, please wait a few minutes and check
        again, or{" "}
        <a href="/" className={styles.resendLink}>
          click here
        </a>{" "}
        to resend it.
      </p>
    </div>
  );
}

export default VerificationPage;
