// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.VerificationPage_verificationContainer__EKaQg {
    max-width: 600px;
    margin: 50px auto;
    padding: 20px;
    text-align: center;
    border: 1px solid #ddd;
    border-radius: 10px;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
  }
  
  .VerificationPage_heading__TXIB- {
    font-size: 24px;
    margin-bottom: 20px;
    color: #333;
  }
  
  .VerificationPage_message__RPM3y {
    font-size: 16px;
    color: #555;
    margin-bottom: 15px;
  }
  
  .VerificationPage_note__Um76h {
    font-size: 14px;
    color: #666;
  }
  
  .VerificationPage_resendLink__50kGL {
    color: #007BFF;
    text-decoration: none;
  }
  
  .VerificationPage_resendLink__50kGL:hover {
    text-decoration: underline;
  }
  `, "",{"version":3,"sources":["webpack://./src/components/VerificationPage.module.css"],"names":[],"mappings":"AAAA;IACI,gBAAgB;IAChB,iBAAiB;IACjB,aAAa;IACb,kBAAkB;IAClB,sBAAsB;IACtB,mBAAmB;IACnB,yCAAyC;EAC3C;;EAEA;IACE,eAAe;IACf,mBAAmB;IACnB,WAAW;EACb;;EAEA;IACE,eAAe;IACf,WAAW;IACX,mBAAmB;EACrB;;EAEA;IACE,eAAe;IACf,WAAW;EACb;;EAEA;IACE,cAAc;IACd,qBAAqB;EACvB;;EAEA;IACE,0BAA0B;EAC5B","sourcesContent":[".verificationContainer {\n    max-width: 600px;\n    margin: 50px auto;\n    padding: 20px;\n    text-align: center;\n    border: 1px solid #ddd;\n    border-radius: 10px;\n    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);\n  }\n  \n  .heading {\n    font-size: 24px;\n    margin-bottom: 20px;\n    color: #333;\n  }\n  \n  .message {\n    font-size: 16px;\n    color: #555;\n    margin-bottom: 15px;\n  }\n  \n  .note {\n    font-size: 14px;\n    color: #666;\n  }\n  \n  .resendLink {\n    color: #007BFF;\n    text-decoration: none;\n  }\n  \n  .resendLink:hover {\n    text-decoration: underline;\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"verificationContainer": `VerificationPage_verificationContainer__EKaQg`,
	"heading": `VerificationPage_heading__TXIB-`,
	"message": `VerificationPage_message__RPM3y`,
	"note": `VerificationPage_note__Um76h`,
	"resendLink": `VerificationPage_resendLink__50kGL`
};
export default ___CSS_LOADER_EXPORT___;
