import axios from "axios";
import config from "../config";

// Create two axios instances - one for auth, one for protected routes
const authApi = axios.create({
  baseURL: config.apiBaseUrl,
  withCredentials: true,
  headers: {
    "Content-Type": "application/json",
  },
  timeout: 10000, // 10 second timeout
  retries: 3,
  retryDelay: 1000,
});
// Add interceptor for retry logic
authApi.interceptors.response.use(null, async (error) => {
  const { config } = error;
  if (!config || !config.retries) {
    return Promise.reject(error);
  }
  config.retries -= 1;

  if (config.retries === 0) {
    return Promise.reject(error);
  }
  // Delay before retrying
  await new Promise((resolve) => setTimeout(resolve, config.retryDelay));

  // Retry the request
  return authApi(config);
});
const protectedApi = axios.create({
  baseURL: config.apiBaseUrl,
  withCredentials: true,
});

// Modify getApplicationId to cache the promise
let applicationIdPromise = null;
const getApplicationId = async () => {
  if (applicationIdPromise) {
    return applicationIdPromise;
  }

  applicationIdPromise = (async () => {
    try {
      // First check localStorage
      const storedAppId = localStorage.getItem("applicationId");
      if (storedAppId) {
        return storedAppId;
      }

      // If not in localStorage, fetch from API
      const response = await protectedApi.get("/api/application/");
      if (response.data?.results?.length > 0) {
        const currentApplication =
          response.data.results[response.data.results.length - 1];
        localStorage.setItem("applicationId", currentApplication.id);
        return currentApplication.id;
      }

      return null;
    } catch (error) {
      console.error("Error getting application ID:", error);
      return null;
    }
  })();

  return applicationIdPromise;
};

// Modify the protectedApi interceptor to include application ID
protectedApi.interceptors.request.use(
  async (config) => {
    // console.log("[DEBUG] API Request starting:", config.url);

    const csrfToken = getCsrfToken();
    if (csrfToken) {
      config.headers["X-CSRFToken"] = csrfToken;
    }

    // Only add application ID for non-POST requests to /api/application/
    if (
      !(
        config.url === "/api/application/" &&
        config.method.toLowerCase() === "post"
      )
    ) {
      const applicationId = await getApplicationId();
      if (applicationId) {
        config.headers["X-Application-ID"] = applicationId;
      }
    }

    console.log("[DEBUG] Request config complete:", {
      url: config.url,
      method: config.method,
      headers: config.headers,
    });

    return config;
  },
  (error) => {
    // console.error("[DEBUG] Request interceptor error:", error);
    return Promise.reject(error);
  }
);

// Add response interceptor for debugging
protectedApi.interceptors.response.use(
  (response) => {
    // console.log("[DEBUG] API Response success:", response.config.url);
    return response;
  },
  (error) => {
    console.error("[DEBUG] API Response error:", {
      url: error.config?.url,
      status: error.response?.status,
      data: error.response?.data,
    });
    return Promise.reject(error);
  }
);

// Function to get CSRF token from cookies
function getCsrfToken() {
  const name = "csrftoken=";
  const decodedCookie = decodeURIComponent(document.cookie);
  const cookieArray = decodedCookie.split(";");

  for (let cookie of cookieArray) {
    cookie = cookie.trim();
    if (cookie.indexOf(name) === 0) {
      return cookie.substring(name.length, cookie.length);
    }
  }
  return null;
}
export { authApi, protectedApi };
